import { Domain } from 'api';

import { slideshowApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type SlideshowProps = keyof Domain.Slideshow;

export const defaultSorting: Domain.Sorting<SlideshowProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Slideshow, SlideshowProps>({
    getState: rootState => rootState.slideshow.overview,
});

export const reducerActions = makeReducerActions<Domain.Slideshow, SlideshowProps>({
    reducerPrefix: '@slideshow/overview',
});

export const actions = makeActions<
    Domain.Slideshow,
    SlideshowProps,
    {
        companyId?: Domain.Company['companyId'];
        branchId?: Domain.Branch['branchId'];
        includeShared?: string;
        ownedByCompany?: string;
    }
>({
    dataTableSaveKey: 'slideshowsOverview-v5',
    loadApi: options =>
        slideshowApi.GetSlideshows(options.ownership, options.pagination, options.sorting, options.search, options.filters, options.locale),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/presentations/slideshows',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Slideshow, SlideshowProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
