import * as React from 'react';

import { RTView, RTValue, RTEditor, Icon, AnchoredPortal, HTMLEditor, HTMLEditorView } from 'ui-components';
import { HTMLEditorValue } from 'utils';

import { store, Actions } from '../state';
import { Frame, MediaItemPickerRenderer, hasEyedropCapableFrameMedia } from '../types';
import FrameEyedropperArea from './FrameEyedropperArea';
import FrameSettingsDialog from './FrameSettingsDialog';

import styles from './styles/FrameBody.scss';

export default function FrameBody(props: {
    gettext: (text: string) => string;
    frame: Frame;
    getMediaItemURL: (mediaItemId: string) => string;
    getVideoDataURL?: (videoURL: string) => string | undefined;
    getVideoMediaItemPreviewURL: (mediaItemId: string) => string;
    disableEditing?: boolean;
    autoPlayVideos: boolean;
    disableVideoPosters: boolean;
    loopVideos: boolean;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
    renderMediaItemPicker: MediaItemPickerRenderer;
    usePreviewImageForContents?: false | string;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onMouseMove?: (x: number, y: number, w: number, h: number) => void;
    onMouseOut?: () => void;
}) {
    const anchor = React.useRef<HTMLDivElement>(null);
    const {
        gettext,
        renderMediaItemPicker,
        frame,
        disableEditing,
        getMediaItemURL,
        getVideoDataURL,
        autoPlayVideos,
        disableVideoPosters,
        loopVideos,
        renderVideo,
        getVideoMediaItemPreviewURL,
    } = props;
    const { state, dispatch } = React.useContext(store);

    const frameIsEdited = !!(!disableEditing && state.editedFrameId && state.editedFrameId === frame.frameId);

    let video: React.ReactNode;
    if (frame.type === 'video' && frame.mediaItemId) {
        const videoURL = getMediaItemURL(frame.mediaItemId);
        let src = videoURL;
        if (getVideoDataURL) {
            src = getVideoDataURL(videoURL) || '';
        }

        video = renderVideo({
            width: '100%',
            height: '100%',
            autoPlay: autoPlayVideos,
            loop: loopVideos,
            src,
            poster: !disableVideoPosters ? getVideoMediaItemPreviewURL(frame.mediaItemId) : undefined,
        });
    }

    return (
        <>
            {frame.type === 'text' && frameIsEdited ? (
                <RTEditor
                    value={new RTValue(frame.text || '', frame.textStyle)}
                    onChangeStart={() => {
                        dispatch({
                            type: Actions.CanvasActions.HISTORY_SAVE,
                        });
                    }}
                    onChange={newValue => {
                        dispatch({
                            type: Actions.SET_FRAME_TEXT,
                            frameId: frame.frameId,
                            text: newValue.text,
                            textStyle: newValue.options || {
                                size: 16,
                            },
                        });
                    }}
                />
            ) : null}

            {frame.type === 'richText' && frameIsEdited ? (
                <HTMLEditor
                    __dangerouslyUseInternalCache={true}
                    gettext={gettext}
                    value={HTMLEditorValue.createFromString(frame.html || '')}
                    onChangeStart={() => {
                        dispatch({
                            type: Actions.CanvasActions.HISTORY_SAVE,
                        });
                    }}
                    onChange={newValue => {
                        dispatch({
                            type: Actions.SET_FRAME_HTML,
                            frameId: frame.frameId,
                            html: HTMLEditorValue.toString(newValue),
                        });
                    }}
                    hideBuiltInToolbar={true}
                />
            ) : null}

            {frame.type === 'text' && !frameIsEdited && !frame.text ? (
                <div className={styles.EmptyFrame}>
                    <Icon type="action_text" />
                </div>
            ) : null}

            {frame.type === 'richText' && !frameIsEdited && !frame.html ? (
                <div className={styles.EmptyFrame}>
                    <Icon type="action_text" />
                </div>
            ) : null}

            {(frame.type === 'image' || frame.type === 'video') && !frameIsEdited && !frame.mediaItemId ? (
                <div className={styles.EmptyFrame}>
                    <Icon type="action_photo" />
                </div>
            ) : null}

            {frame.type === 'text' && frame.text && !frameIsEdited ? <RTView value={new RTValue(frame.text, frame.textStyle)} /> : null}

            {frame.type === 'richText' && frame.html && !frameIsEdited ? <HTMLEditorView value={frame.html} /> : null}

            {frame.type === 'image' && frame.mediaItemId ? (
                <img
                    className={frame.imageCrop || 'proportional-inside'}
                    src={getMediaItemURL(frame.mediaItemId)}
                    alt=""
                    style={{
                        objectFit: frame.imageCrop === 'stretch-fit' ? 'fill' : 'contain',
                    }}
                />
            ) : null}

            {frame.type === 'video' && frame.mediaItemId ? video : null}

            {state.eyedropperEnabled && hasEyedropCapableFrameMedia(frame) ? (
                <FrameEyedropperArea
                    onMouseDown={props.onMouseDown}
                    onMouseUp={props.onMouseUp}
                    onMouseMove={props.onMouseMove}
                    onMouseOut={props.onMouseOut}
                    style={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                />
            ) : null}

            <div
                ref={anchor}
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                }}
            >
                {frameIsEdited ? (
                    <AnchoredPortal anchorElement={anchor.current}>
                        <FrameSettingsDialog
                            dialog={{
                                frame: state.canvas.frames.find(searchedFrame => searchedFrame.frameId === state.editedFrameId)! as Frame,
                            }}
                            gettext={gettext}
                            renderMediaItemPicker={renderMediaItemPicker}
                        />
                    </AnchoredPortal>
                ) : null}
            </div>
        </>
    );
}
