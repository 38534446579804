import * as React from 'react';

import { loadImageFromCache } from 'utils';

import { store, Actions } from '../state';
import { getSelectedImage } from '../utils';

export default function ProductImageSizeComputer(props: { proxyImageURL: (url: string) => string; usePreviewImageForContents: boolean }) {
    const { state, dispatch } = React.useContext(store);

    React.useEffect(() => {
        if (props.usePreviewImageForContents) {
            return;
        }

        const loadImage = (imageUrl: string) => {
            if (!state.productImageSizes.get(imageUrl)) {
                loadImageFromCache(imageUrl, props.proxyImageURL(imageUrl), img => {
                    state.productImageSizes.set(imageUrl, {
                        width: img.width,
                        height: img.height,
                    });
                    dispatch({
                        type: Actions.FORCE_REFRESH,
                    });
                });
            }
        };

        for (const availableProduct of state.availableProducts) {
            const selectedImage = getSelectedImage(undefined, availableProduct);
            loadImage(selectedImage.url);
        }

        for (const shelf of state.canvas.shelves) {
            if (shelf.type === 'productsShelf') {
                for (const placedProduct of shelf.products) {
                    const availableProduct = state.availableProducts.find(
                        availableProduct => availableProduct.productId === placedProduct.productId,
                    );

                    if (availableProduct) {
                        const selectedImage = getSelectedImage(placedProduct, availableProduct);
                        loadImage(selectedImage.url);
                    }
                }
            }
        }
    }, [state.availableProducts, state.productImageSizes, state.canvas.shelves, props.usePreviewImageForContents]);

    return <span />;
}
