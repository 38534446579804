export interface GoodieBagPickup {
    goodieBagPickupId: string;
    goodieBagPickupCode: string;
    goodieBagPickupStatus: 'unused' | 'used' | 'ongoing';
    createdAt?: string;
    updatedAt?: string;
}

export interface DeviceGoodieBagPickupsResponse {
    total: number;
    items: GoodieBagPickup[];
}

export interface UpdateDeviceGoodieBagConfigurationPayload {
    deviceId: string;
    isGoodieBagEnabled: boolean;
    goodieBagProductId?: string | null;
    useSingleSharedGoodieBagPickupCode: boolean;
    sharedGoodieBagPickupCode?: string;
}
export interface CreateGoodieBagPickupPayload {
    companyId: string;
    branchId: string;
    deviceId: string;
    goodieBagPickupCode: string;
}

export interface GenerateGoodieBagPickupsPayload {
    companyId: string;
    branchId: string;
    deviceId: string;
    numberOfGoodieBagPickups: string;
}

export interface UpdateGoodieBagPickupStatusPayload {
    goodieBagPickupId: string;
    goodieBagPickupStatus: 'unused' | 'used';
}

export interface RemoveAllGoodieBagPickupsPayload {
    deviceId: string;
}

export const GOODIE_BAG_CODES_EXPORT_TYPES = ['pdf', 'csv', 'xlsx', 'zip'] as const;
export type GoodieBagCodesExportType = (typeof GOODIE_BAG_CODES_EXPORT_TYPES)[number];
