import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { tagsApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    tag?: Domain.Tag;
}

const initialState: State = {};

const reducerActions = {
    setTag: createAction('@tag/update/setTag', withPayloadType<Domain.Tag>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setTag, (state, action) => {
        state.tag = action.payload;
    }),
);

export const selectTag: Selector<RootState, Domain.Tag> = state => {
    const tag = state.tags.update.tag;
    if (!tag) {
        throw new Error('Tag not loaded');
    }

    return tag;
};

export const loadTag =
    (tagId: string): ThunkAction<Promise<Domain.Tag>> =>
    async dispatch => {
        const tag = await tagsApi.GetTagDetails(tagId);

        await dispatch(reducerActions.setTag(tag));

        return tag;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(loadTag(options.urlParams.tagId))]);
    };
