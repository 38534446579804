import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { setContextProperty, clearContextProperty, getContextProperty, getLogActivityFn, log } from './Logger';

async function vendingMachineLog(status: 'info' | 'warn' | 'error', type: string, messageContext?: object) {
    log(status, type, messageContext);

    const createDeviceActivityLogFn = getLogActivityFn();
    const deviceId = getContextProperty('deviceId') as string | undefined;
    const branchId = getContextProperty('branchId') as string | undefined;
    const companyId = getContextProperty('companyId') as string | undefined;
    const shoppingSessionId = getContextProperty('shoppingSessionId') as string | undefined;
    const orderId = getContextProperty('orderId') as string | undefined;
    const pickupId = getContextProperty('reservationId') as string | undefined;

    if (!createDeviceActivityLogFn || !deviceId || !shoppingSessionId || !branchId || !companyId) {
        return;
    }

    await createDeviceActivityLogFn({
        subjects: [shoppingSessionId, orderId, pickupId].filter(Boolean) as string[],
        companyId,
        branchId,
        log: {
            eventName: type,
            status,
            payload: messageContext,
        },
        createdOnInMilliseconds: moment.utc().valueOf(),
    });
}

export function setPickupId(pickupId: string) {
    setContextProperty('reservationId', pickupId);
}

export function clearPickupId() {
    clearContextProperty('reservationId');
}

export function setOrderId(orderId: string) {
    setContextProperty('orderId', orderId);
}

export function clearOrderId() {
    clearContextProperty('orderId');
}

export function resetShoppingSession() {
    clearOrderId();
    clearPickupId();
    setContextProperty('shoppingSessionId', uuidv4());
}

export function getShoppingSessionId(): string | undefined {
    const shoppingSessionId = getContextProperty('shoppingSessionId');
    const isAString = typeof shoppingSessionId === 'string' || shoppingSessionId instanceof String;
    if (!isAString) {
        return undefined;
    }

    return shoppingSessionId as string;
}

export function endShoppingSession(reason: 'INACTIVITY' | 'ORDER_PROCESS_FINISHED') {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_END', { reason });
    resetShoppingSession();
}

interface Cart {
    currency: {
        name: string;
        symbol: string;
    };
    total: number;
    items: {
        productId: string;
        productCode: string;
        productName?: object | null;
        quantity: number;
        unitPrice: number | null;
    }[];
}

let oldCart: Cart | null = null;

export function cartChaged(cart: Cart, changeReason: string) {
    if (JSON.stringify(oldCart) === JSON.stringify(cart)) {
        return;
    }

    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_CART_CHANGE', { cart: { ...cart, changeReason } });

    oldCart = cart;
}

export function clickNavigation(
    route: {
        route: string;
        [key: string]: any;
    },
    replace: boolean,
    source: string,
) {
    vendingMachineLog('info', 'DEVICE_MATIC_CLICK_NAVIGATION', { route, replace, source });
}

export function autoNavigation(
    route: {
        route: string;
        [key: string]: any;
    },
    replace: boolean,
    source: string,
) {
    vendingMachineLog('info', 'DEVICE_MATIC_AUTO_NAVIGATION', { route, replace, source });
}

export function routeChange(
    route: {
        route: string;
        [key: string]: any;
    },
    title?: object | null,
) {
    vendingMachineLog('info', 'DEVICE_MATIC_ROUTE_CHANGE', { route, title });
}

export function orderCreated() {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_ORDER_CREATE');
}
export function reuseExistingOrder() {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_ORDER_REUSED');
}
export function orderStatusChanged(newOrderStatus: string, oldOrderStatus: string) {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_ORDER_STATUS', { newOrderStatus, oldOrderStatus });
}

export function pickupStatusChanged(newPickupStatus: string, oldPickupStatus: string) {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_RESERVATION_STATUS', {
        newReservationStatus: newPickupStatus,
        oldReservationStatus: oldPickupStatus,
    });
}

export function transactionStart() {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_TRANSACTION_START');
}

export function transactionStatus(transactionStatus: string) {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_TRANSACTION_STATUS', { transactionStatus });
}

export function transactionFailed() {
    vendingMachineLog('error', 'DEVICE_MATIC_SHOPPING_SESSION_TRANSACTION_STATUS', { transactionStatus: 'FAILED' });
}

export function transactionSuccess() {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_TRANSACTION_STATUS', { transactionStatus: 'PAID' });
}

export function transactionCanceled() {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_TRANSACTION_STATUS', { transactionStatus: 'CANCELED' });
}

export function pickUpFromRobotSuccess() {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_PICK_UP_ROBOT_SUCCESS');
}

export function pickUpFromLockersSuccess() {
    vendingMachineLog('info', 'DEVICE_MATIC_SHOPPING_SESSION_PICK_UP_LOCKERS_SUCCESS');
}

export function pickUpFromRobotFailed() {
    vendingMachineLog('error', 'DEVICE_MATIC_SHOPPING_SESSION_PICK_UP_ROBOT_FAILED');
}

export function pickUpFromLockersFailed() {
    vendingMachineLog('error', 'DEVICE_MATIC_SHOPPING_SESSION_PICK_UP_LOCKERS_FAILED');
}

export function hardwareStatus(hardwareStatus: string) {
    vendingMachineLog('info', 'DEVICE_MATIC_HARDWARE_STATUS', { hardwareStatus });
}

export function hardwareFault(hardwareFault: string) {
    vendingMachineLog('error', 'DEVICE_MATIC_HARDWARE_FAULT', { hardwareFault });
}

export function unexpectedSituation(description: string) {
    vendingMachineLog('error', 'DEVICE_MATIC_UNEXPECTED_SITUATION', { description });
}

export function lockersLeftOpenedAfterDelivery() {
    vendingMachineLog('error', 'DEVICE_COLLECT_LOCKERS_LEFT_OPENED_AFTER_DELIVERY');
}

export function productCatalogLoad() {
    vendingMachineLog('info', 'DEVICE_MATIC_PRODUCT_CATALOG_LOAD', {});
}

export function productStockInfo(stock: {
    productId: string;
    productCodes: string;
    productName?: object | null;
    robotStock: number;
    lockerStock: number;
}) {
    vendingMachineLog('info', 'DEVICE_MATIC_PRODUCT_STOCK_INFO', { stock });
}

export function deliveryDecided(delivery: {
    robot: {
        barcode: string;
        quantity: number;
    }[];
    lockers: {
        barcode: string;
        moduleId: string;
        lockerId: string;
    }[];
}) {
    vendingMachineLog('info', 'DEVICE_VENDING_MACHINE_DELIVERY_DECIDED', { delivery });
}

export function deliveryStatusChange(
    deliveryId: string,
    deliveryStatus:
        | 'initiate'
        | 'delivery-started'
        | 'delivery-start-failed'
        | 'delivery-failed'
        | 'delivered'
        | 'awaiting-confirmation'
        | 'picked-up'
        | 'pick-up-failed',
) {
    vendingMachineLog('info', 'DEVICE_VENDING_MACHINE_DELIVERY_STATUS_CHANGE', { deliveryId, deliveryStatus });
}

export function openLockers(
    lockers: {
        moduleId: string;
        lockerId: string;
    }[],
) {
    vendingMachineLog('info', 'DEVICE_VENDING_MACHINE_OPEN_LOCKERS', { lockers });
}

export function openLockerFailed(locker: { moduleId: string; lockerId: string }) {
    vendingMachineLog('error', 'DEVICE_VENDING_MACHINE_FAILED_TO_OPEN_LOCKER', { locker });
}
export function checkoutPaymenyType(paymentType?: string) {
    vendingMachineLog('info', 'DEVICE_MATIC_SELECT_PAYMENT_METHOD', { paymentType });
}
export function checkoutPhase(checkoutPhase: string, status: 'info' | 'warn' | 'error' = 'info') {
    vendingMachineLog(status, 'DEVICE_VENDING_MACHINE_CHECKOUT_PHASE', { checkoutPhase });
}

export function refreshedCollectStock() {
    vendingMachineLog('info', 'DEVICE_VENDING_MACHINE_REFRESHED_COLLECT_STOCK');
}
