import { Domain } from 'api';

import { tagsApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type TagProps = keyof Domain.Tag;

export const defaultSorting: Domain.Sorting<TagProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Tag, TagProps>({
    getState: rootState => rootState.tags.overview,
});

export const reducerActions = makeReducerActions<Domain.Tag, TagProps>({
    reducerPrefix: '@tag/overview',
});

export const actions = makeActions<Domain.Tag, TagProps>({
    dataTableSaveKey: 'tag-v1',
    loadApi: options => tagsApi.GetTags(options.ownershipIds, options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/branch/tags',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Tag, TagProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
