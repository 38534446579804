import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { useLocalize, I18n, getCountryCodeFromLocale, getLanguageCodeFromLocale, COUNTRY_CONFIG, ALL_LOCALES } from 'data-store';
import { color, Tx } from 'ui-components';

export default function LocalizedValue(props: {
    value?: Domain.LocalizedValue | null | undefined;
    default?: string;
    locale?: Domain.Locale;
    fallback?: boolean;
    hideFallbackLangPrefix?: boolean;
    preferredFallback?: Domain.Locale;
}) {
    const localize = useLocalize();
    const uiLocale = useSelector(I18n.selectCurrentLocale);

    if (!props.value) {
        return <React.Fragment>{props.default}</React.Fragment> || null;
    }

    const localizedValue = localizeWithFallback({
        value: props.value,
        localize,
        toLocale: props.locale || uiLocale,
        fallback: props.fallback,
        addFallbackLangPrefix: !props.hideFallbackLangPrefix,
        plaintext: false,
        preferredFallback: props.preferredFallback,
    });

    if (localizedValue) {
        return <React.Fragment>{localizedValue}</React.Fragment>;
    }

    return <React.Fragment>{props.default}</React.Fragment> || null;
}

export function localizeWithFallback({
    value,
    localize,
    toLocale,
    fallback,
    addFallbackLangPrefix = true,
    plaintext,
    preferredFallback,
    preferMainValueStructure,
}: {
    value: Domain.LocalizedValue;
    localize: ReturnType<typeof useLocalize>;
    toLocale: Domain.Locale;
    fallback?: boolean;
    addFallbackLangPrefix?: boolean;
    plaintext?: boolean;
    preferredFallback?: Domain.Locale;
    preferMainValueStructure?: boolean;
}) {
    let fallbackValue = localize(value, toLocale);
    let prefix = null;

    if (!fallbackValue && fallback) {
        const country = toLocale ? getCountryCodeFromLocale(toLocale) : 'BE';
        const countryLocales = COUNTRY_CONFIG[country].locales;

        for (const fallbackLocale of countryLocales) {
            if (fallbackLocale === toLocale) {
                continue;
            }
            fallbackValue = attemptToLocalize(fallbackValue, value, localize, fallbackLocale);
            if (fallbackValue) {
                prefix = '(' + getLanguageCodeFromLocale(fallbackLocale).toUpperCase() + ') ';
                break;
            }
        }

        if (!fallbackValue) {
            if (preferredFallback) {
                fallbackValue = attemptToLocalize(fallbackValue, value, localize, preferredFallback);
                if (fallbackValue) {
                    prefix = '(' + getLanguageCodeFromLocale(preferredFallback).toUpperCase() + ') ';
                }
            }
        }

        if (!fallbackValue) {
            for (const fallbackLocale of ALL_LOCALES) {
                if (fallbackLocale === toLocale) {
                    continue;
                }
                fallbackValue = attemptToLocalize(fallbackValue, value, localize, fallbackLocale);
                if (fallbackValue) {
                    prefix = '(' + getLanguageCodeFromLocale(fallbackLocale).toUpperCase() + ') ';
                    break;
                }
            }
        }
    }

    if (fallbackValue) {
        if (plaintext) {
            return (prefix && addFallbackLangPrefix ? prefix + ' ' : '') + fallbackValue;
        }
        if (preferMainValueStructure) {
            return fallbackValue;
        }
        return (
            <React.Fragment>
                {prefix && addFallbackLangPrefix ? (
                    <Tx
                        level="body-sm"
                        sx={{ emphasize: true }}
                        className={color.Grey.Dark2}
                    >
                        {prefix}
                    </Tx>
                ) : null}
                <span dangerouslySetInnerHTML={{ __html: fallbackValue }} />
            </React.Fragment>
        );
    }
}

function attemptToLocalize(
    localizedValue: string | null | undefined,
    value: Domain.LocalizedValue | null | undefined,
    localize: ReturnType<typeof useLocalize>,
    locale: Domain.Locale,
) {
    if (value && !localizedValue) {
        localizedValue = localize(value, locale);
    }

    return localizedValue;
}
