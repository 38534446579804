import * as React from 'react';

import { loadImageFromCache } from 'utils';

import { store, Actions } from '../state';
import { getProductImages, getSelectedImage } from '../utils';

export default function ProductImageSizeComputer(props: { proxyImageURL: (url: string) => string }) {
    const { state, dispatch } = React.useContext(store);

    React.useEffect(() => {
        const loadImage = (imageUrl: string) => {
            if (!state.productImageSizes.get(imageUrl)) {
                loadImageFromCache(imageUrl, props.proxyImageURL(imageUrl), img => {
                    state.productImageSizes.set(imageUrl, {
                        width: img.width,
                        height: img.height,
                    });
                    dispatch({
                        type: Actions.FORCE_REFRESH,
                    });
                });
            }
        };

        for (const frame of state.canvas.frames) {
            if (frame.type === 'product') {
                const availableProduct = state.availableProducts.find(availableProduct => availableProduct.productId === frame.productId);

                if (availableProduct) {
                    const productImages = getProductImages(availableProduct);
                    const selectedImage = getSelectedImage(productImages, frame.productMediaType, frame.imageId);
                    loadImage(selectedImage.url);
                }
            }
        }
    }, [state.availableProducts, state.productImageSizes, state.canvas.frames]);

    return <span />;
}
