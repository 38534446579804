import { Domain } from 'api';
import { config } from 'config';

import { getScaledScreenSizeInPX, getScreenSizeInMM } from './';
const CM_TO_MM = 10;
// TV screen size in px: 1080W x 1920H
// TV screen size in mm: 680W  x 1200H

// New Matic screen size in px: 1280W x 962H
// New Matic screen size in mm: 400W  x 300H

export interface ProductImageSize {
    width: number;
    height: number;
}

export function convertToMM(value: number, unit: string): number {
    switch (unit) {
        case 'cm':
            return value * CM_TO_MM;
        case 'mm':
        default:
            return value;
    }
}
export const getProductDimensionsInMM = (dimensions: Domain.ProductDetailsDimensions) => {
    const depthInMM = dimensions.depthWithUnit
        ? convertToMM(dimensions.depthWithUnit.value, dimensions.depthWithUnit.unit)
        : dimensions.depth || 0;

    const lengthInMM = dimensions.lengthWithUnit
        ? convertToMM(dimensions.lengthWithUnit.value, dimensions.lengthWithUnit.unit)
        : dimensions.length || 0;
    const widthInMM = dimensions.widthWithUnit
        ? convertToMM(dimensions.widthWithUnit.value, dimensions.widthWithUnit.unit)
        : dimensions.width || 0;

    return {
        depth: depthInMM,
        length: lengthInMM,
        width: widthInMM,
    };
};
export const computeProductSize = (
    screenResolution: Domain.DeviceScreenResolution,
    availableProduct: Domain.SlideshowProduct,
    productImageUrl: string,
    productImageSize: ProductImageSize,
    shouldDefaultTo90: boolean,
    placedProductVersion: number,
): ProductImageSize => {
    const screenShortEdgeInPX = getScaledScreenSizeInPX(screenResolution, placedProductVersion).width;
    const screenShortEdgeInMM = getScreenSizeInMM(screenResolution).width;

    const largestDimensionInPX = Math.max(productImageSize.width, productImageSize.height);
    const { depth, length, width } = getProductDimensionsInMM(availableProduct);
    let largestDimensionInMM = Math.max(depth, length, width);

    if (productImageUrl === config.productImagePlaceholder) {
        largestDimensionInMM = 90;
    } else if (largestDimensionInMM === 0) {
        largestDimensionInMM = 90;
    }
    // any product dimension larger than 500mm is probably bad data in Medipim
    else if (largestDimensionInMM > 500 && shouldDefaultTo90) {
        largestDimensionInMM = 90;
    }

    const screenDensity = screenShortEdgeInPX / screenShortEdgeInMM;
    const imageDensity = largestDimensionInPX / largestDimensionInMM;

    return {
        width: Math.floor((productImageSize.width * screenDensity) / imageDensity),
        height: Math.floor((productImageSize.height * screenDensity) / imageDensity),
    };
};
