import { Domain } from 'api';
import { config } from 'config';

export default function getPreferredProductImageURL(image?: Domain.ProductImage, thumbnail?: boolean): string | undefined {
    const noImageUrl = config.productImagePlaceholder;

    if (!image) {
        return noImageUrl;
    }
    let url;

    if (thumbnail) {
        if (image.formats['450x450']) {
            url = image.formats['450x450'];
        } else if (image.formats['900x900']) {
            url = image.formats['900x900'];
        } else if (image.formats['200x200']) {
            url = image.formats['200x200'];
        }
    } else {
        url = image.formats.original || image.formats.originalJpeg || image.formats['900x900'] || image.formats.mediumJpeg;
    }

    if (url && !url.startsWith('http')) {
        url = config.apiBaseUrl + url;
    }

    if (url === null) {
        return noImageUrl;
    }

    return url;
}
