import { Domain } from 'api';

import { mediaItemApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type MediaItemProps = keyof Domain.MediaItem;

export const defaultSorting: Domain.Sorting<MediaItemProps> = {
    field: 'updatedAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.MediaItem, MediaItemProps>({
    getState: rootState => rootState.media.overview,
});

export const reducerActions = makeReducerActions<Domain.MediaItem, MediaItemProps>({
    reducerPrefix: '@media/overview',
});

export const actions = makeActions<
    Domain.MediaItem,
    MediaItemProps,
    {
        type?: Domain.MediaItemType;
        companyId?: Domain.MediaItem['companyId'];
        branchName?: Domain.MediaItem['branchName'];
        includeShared?: string;
        ownedByCompany?: string;
    }
>({
    dataTableSaveKey: 'mediaOverview-v5',
    loadApi: options =>
        mediaItemApi.GetMediaItems(
            options.ownership,
            options.pagination,
            options.sorting,
            options.filters.type,
            options.search,
            options.filters,
            options.locale,
        ),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/media',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.MediaItem, MediaItemProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
