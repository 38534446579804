import {
    DeviceGoodieBagPickupsResponse,
    UpdateDeviceGoodieBagConfigurationPayload,
    CreateGoodieBagPickupPayload,
    GenerateGoodieBagPickupsPayload,
    UpdateGoodieBagPickupStatusPayload,
    RemoveAllGoodieBagPickupsPayload,
    GoodieBagCodesExportType,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class DeviceGoodieBagEndpoint extends Endpoint {
    public async UpdateDeviceGoodieBagConfiguration(payload: UpdateDeviceGoodieBagConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceGoodieBagConfiguration',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceGoodieBagConfiguration\\UpdateDeviceGoodieBagConfiguration',
                payload,
            },
        });
    }

    public async CreateGoodieBagPickup(payload: CreateGoodieBagPickupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateGoodieBagPickup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\GoodieBag\\CreateGoodieBagPickup\\CreateGoodieBagPickup',
                payload,
            },
        });
    }

    public async GenerateGoodieBagPickups(payload: GenerateGoodieBagPickupsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/GenerateGoodieBagPickups',
            body: {
                commandName: 'App\\Domain\\WriteModel\\GoodieBag\\GenerateGoodieBagPickups\\GenerateGoodieBagPickups',
                payload,
            },
        });
    }
    public async UpdateGoodieBagPickupStatus(payload: UpdateGoodieBagPickupStatusPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateGoodieBagPickupStatus',
            body: {
                commandName: 'App\\Domain\\WriteModel\\GoodieBag\\UpdateGoodieBagPickupStatus\\UpdateGoodieBagPickupStatus',
                payload,
            },
        });
    }

    public async RemoveAllGoodieBagPickups(payload: RemoveAllGoodieBagPickupsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveAllGoodieBagPickups',
            body: {
                commandName: 'App\\Domain\\WriteModel\\GoodieBag\\RemoveAllGoodieBagPickups\\RemoveAllGoodieBagPickups',
                payload,
            },
        });
    }

    public async GetDeviceGoodieBagPickups(
        deviceId: string,
        filters?: { goodieBagPickupStatus?: 'unused' | 'used' },
    ): Promise<DeviceGoodieBagPickupsResponse> {
        const response = await this.client.get({
            url: `/device/${deviceId}/goodie-bag-pickups`,
            params: {
                filters,
            },
        });
        this.guardResponseSchema('#/definitions/DeviceGoodieBagPickupsResponse', response);

        return response;
    }
    public GetDeviceGoodieBagPickupCodesExportDownloadURL({
        deviceId,
        exportType,
    }: {
        deviceId: string;
        exportType: GoodieBagCodesExportType;
    }): string {
        const params = {
            filters: {
                goodieBagPickupStatus: 'unused',
            },
        };
        return this.client.buildApiURL(`/device/${deviceId}/goodie-bag-pickup-codes/${exportType}`, true, params);
    }
}
