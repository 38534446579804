import {
    Ownership,
    Pagination,
    Sorting,
    PickupStatus,
    Pickup,
    PickupsPage,
    BacksideStockingPickupsPage,
    PickupDetails,
    CreatePickupPayload,
    CancelPickupPayload,
    OverrideOrderLinesPayload,
    SendPickupCanceledByPharmacistEmailPayload,
    SendPickupFailedForCustomerMailPayload,
    SendPickupFailedForPharmacistMailPayload,
    UpdatePickupRemarksPayload,
    UpdatePickupCustomerEmailAddressPayload,
    UpdatePickupCustomerPhoneNumberPayload,
    VendingMachineDeliveryStatus,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class PickupEndpoint extends Endpoint {
    public async GetPickups(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            deliveries?: VendingMachineDeliveryStatus;
            pickupStatus?: PickupStatus;
            orderId?: Pickup['orderId'];
            companyId?: string;
            branchId?: string;
            configuredIntegrationIds?: string[];
        },
    ): Promise<PickupsPage> {
        let url = '';
        if (ownership.type === 'branch') {
            url = '/pickup/overview/branch';
        } else if (ownership.type === 'company') {
            url = '/pickup/overview/company';
        } else {
            throw new Error('Not allowed to access pickups');
        }
        const pickupStatuses = filters?.pickupStatus?.split(',').filter(Boolean);
        delete filters?.pickupStatus;
        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters: {
                    ...filters,
                    deliveryStatus: filters?.deliveries?.split(',').filter(Boolean),
                    deliveries: null,
                    pickupStatuses,
                },
            },
        });

        this.guardResponseSchema('#/definitions/PickupsPage', response);

        return response;
    }

    public async GetPickupDetails(pickupId: Pickup['pickupId']): Promise<PickupDetails> {
        const response = await this.client.get({
            url: `/pickup/${pickupId}/details`,
        });

        this.guardResponseSchema('#/definitions/PickupDetails', response);

        return response;
    }

    public async FindPickupByCode(pickupCode: Pickup['pickupCode']): Promise<PickupDetails> {
        const response = await this.client.get({
            url: `/pickup/code/${pickupCode}/details`,
        });

        this.guardResponseSchema('#/definitions/PickupDetails', response);

        return response;
    }

    public async GetPickupsForDevice(
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            pickupStatus?: PickupStatus;
            configuredIntegrationIds?: string[];
        },
    ): Promise<PickupsPage> {
        const response = await this.client.get({
            url: '/pickup/overview/device',
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/PickupsPage', response);

        return response;
    }

    public async GetPickupsForBacksideStocking(
        sorting: Sorting,
        filters?: {
            configuredIntegrationIds?: string[];
        },
    ): Promise<BacksideStockingPickupsPage> {
        const response = await this.client.get({
            url: '/pickup/overview/backside-stocking',
            params: {
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/BacksideStockingPickupsPage', response);

        return response;
    }

    public async CreatePickup(payload: CreatePickupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreatePickup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\CreatePickup\\CreatePickup',
                payload,
            },
        });
    }

    public async CancelPickup(payload: CancelPickupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CancelPickup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\CancelPickup\\CancelPickup',
                payload,
            },
        });
    }

    public async OverrideOrderLines(payload: OverrideOrderLinesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/OverrideOrderLines',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\OverrideOrderLines\\OverrideOrderLines',
                payload,
            },
        });
    }

    public async SendPickupCanceledByPharmacistEmail(payload: SendPickupCanceledByPharmacistEmailPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SendPickupCanceledByPharmacistEmail',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\SendPickupCanceledByPharmacistEmail\\SendPickupCanceledByPharmacistEmail',
                payload,
            },
        });
    }

    public async SendPickupFailedForCustomerMail(payload: SendPickupFailedForCustomerMailPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SendPickupFailedForCustomerMail',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\SendPickupFailedForCustomerMail\\SendPickupFailedForCustomerMail',
                payload,
            },
        });
    }

    public async SendPickupFailedForPharmacistMail(payload: SendPickupFailedForPharmacistMailPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SendPickupFailedForPharmacistMail',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\SendPickupFailedForPharmacistMail\\SendPickupFailedForPharmacistMail',
                payload,
            },
        });
    }

    public async UpdatePickupRemarks(payload: UpdatePickupRemarksPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdatePickupRemarks',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\UpdatePickupRemarks\\UpdatePickupRemarks',
                payload,
            },
        });
    }

    public async UnreserveProductsFromPickup(payload: { pickupId: Pickup['pickupId'] }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UnreserveProductsFromPickup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\UnreserveProductsFromPickup\\UnreserveProductsFromPickup',
                payload,
            },
        });
    }

    public async UpdatePickupCustomerEmailAddress(payload: UpdatePickupCustomerEmailAddressPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdatePickupCustomerEmailAddress',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\UpdatePickupCustomerEmailAddress\\UpdatePickupCustomerEmailAddress',
                payload,
            },
        });
    }

    public async UpdatePickupCustomerPhoneNumber(payload: UpdatePickupCustomerPhoneNumberPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdatePickupCustomerPhoneNumber',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\UpdatePickupCustomerPhoneNumber\\UpdatePickupCustomerPhoneNumber',
                payload,
            },
        });
    }

    public GetDataMatrixImageUrl(pickupCode: string): string {
        return this.client.buildApiURL(`/pickup/${pickupCode}/datamatrix`, true);
    }

    public GetOverboxEanImageUrl(eanCode: string): string {
        return this.client.buildApiURL(`/pickup/ean-barcode/${eanCode}`, true);
    }
}
