import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';
import { VendingMachineLog } from 'logger';
import * as DeviceInterface from 'meditech-device-interface';

import { withPayloadType, ThunkAction } from '@/action';
import { vendingMachineCatalogApi, productSelectionApi, collectApi, deviceApi, deviceGoodieBagApi } from '@/api';
import { meditechDeviceApi } from '@/meditechDeviceApi';
import { RootState } from '@/store';

import { PRODUCT_CODES_FOR_SIMULATED_LOCKERS, PRODUCT_CODES_FOR_SIMULATED_STANDALONE_MATIC } from './constants';
import { selectDevice } from './selectors';
import { getDeviceCountry } from './utils';
import { VendingMachineCatalog, productCodesToList, ProductDetails } from './VendingMachineCatalog';

export interface VendingMachineState {
    catalog?: VendingMachineCatalog;
    robotIsReadyToDeliver: boolean;
    collectLockerGroupConfiguration?: Domain.CollectConfiguration;
    goodieBagPickups?: Domain.GoodieBagPickup[];
}

const initialState: VendingMachineState = {
    robotIsReadyToDeliver: true,
    goodieBagPickups: [],
};

export const reducerActions = {
    setCatalog: createAction('@tvScreen/vendingMachine/setCatalog', withPayloadType<VendingMachineCatalog>()),
    setRobotIsReadyToDeliver: createAction('@tvScreen/vendingMachine/setRobotIsReadyToDeliver', withPayloadType<boolean>()),
    setCollectLockerGroupConfiguration: createAction(
        '@tvScreen/vendingMachine/setCollectLockerGroupConfiguration',
        withPayloadType<Domain.CollectConfiguration | undefined>(),
    ),
};

export const vendingMachineReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setCatalog, (state, action) => {
            state.catalog = action.payload;
        })
        .addCase(reducerActions.setRobotIsReadyToDeliver, (state, action) => {
            state.robotIsReadyToDeliver = action.payload;
        })
        .addCase(reducerActions.setCollectLockerGroupConfiguration, (state, action) => {
            state.collectLockerGroupConfiguration = action.payload;
        }),
);

export const selectCatalog: Selector<RootState, VendingMachineCatalog | undefined> = state => state.tvScreen.vendingMachine.catalog;
export const selectRobotIsReadyToDeliver: Selector<RootState, boolean> = state =>
    state.tvScreen.vendingMachine.robotIsReadyToDeliver || false;
export const selectCollectLockerGroupConfiguration: Selector<RootState, Domain.CollectConfiguration | undefined> = state =>
    state.tvScreen.vendingMachine.collectLockerGroupConfiguration;

export function SIMULATED_getRobotStock(barcodes: (string | undefined)[]): Domain.VendingMachineStockItem[] {
    const stock: Domain.VendingMachineStockItem[] = [];

    for (const barcode of barcodes) {
        if (barcode === undefined) {
            continue;
        }

        stock.push({
            barcodes: [barcode],
            quantity: barcode.indexOf('3') === 0 ? 0 : 3,
            location: {
                type: 'robot',
            },
        });
    }

    return stock;
}

export function SIMULATED_getLockerStock(lockers: Domain.CollectStockAndStatuses): Domain.VendingMachineStockItem[] {
    const stock: Domain.VendingMachineStockItem[] = [];
    const usedLockers: Domain.LockerAddress[] = [];

    const getFirstUnusedLocker = (): Domain.LockerAddress | undefined => {
        return lockers.find(locker => {
            const isUsed = usedLockers.find(
                usedLocker => usedLocker.moduleId === locker.moduleId && usedLocker.lockerId === locker.lockerId,
            );
            if (!isUsed && locker.isEnabled && !locker.pickupId && locker.items.length === 0 && locker.status === 'locked') {
                return true;
            }
            return false;
        });
    };

    for (const barcode of PRODUCT_CODES_FOR_SIMULATED_LOCKERS) {
        if (barcode === undefined) {
            continue;
        }

        const locker = getFirstUnusedLocker();
        if (!locker) {
            break;
        }

        usedLockers.push(locker);

        stock.push({
            barcodes: [barcode],
            quantity: 1,
            location: {
                type: 'collect',
                ...locker,
            },
        });
    }

    return stock;
}

export const ClearHardwareState = (): ThunkAction => async () => {
    await meditechDeviceApi.ClearState();
};

export const GetGoodieBagPickupCodes =
    (deviceId?: string): ThunkAction<Promise<Domain.GoodieBagPickup[]>> =>
    async (_, getState) => {
        const state = getState();
        const device = selectDevice(state);
        const deviceID = deviceId || device?.deviceId;
        if (!deviceID) {
            return [];
        }
        const data = await deviceGoodieBagApi.GetDeviceGoodieBagPickups(deviceID, {
            goodieBagPickupStatus: 'unused',
        });
        return data.items;
    };

export const LoadProductDetails =
    (productId: string, locale?: Domain.Locale): ThunkAction<Promise<ProductDetails | undefined>> =>
    async (_1, getState) => {
        const state = getState();
        const device = selectDevice(state);

        if (device) {
            const data = await productSelectionApi.GetSelectedProductDetails(
                {
                    type: 'branch',
                    ownerId: device.branchId,
                },
                productId,
                'robotProductSelection',
                locale,
            );

            if (!data) {
                return undefined;
            }

            return {
                images: data.images,
                descriptions: data.localizedDescriptions,
            };
        }

        return undefined;
    };

const initializeVision =
    (device: Domain.DeviceDetails): ThunkAction =>
    async () => {
        const deviceConfiguration: DeviceInterface.IDeviceConfiguration = {
            DeviceType: DeviceInterface.IDeviceType.VISION,
            IsCollect: false,
            IsMaticSlim: device.screenResolution === '1280x1024',
            HasRobotConnection: device.robotStockIsAvailable,
            RobotIpPort: device.robotUrl,
            RobotStockLocation: device.robotStockLocations,
            RobotZone: device.robotZone,
            RobotDeliveryExit: device.robotOutputDestination,
            RobotTransportTime: device.transportTimeFromRobot,
            PharmacySoftwareIpPort: device.configuration.nextpharmWebSocket,
            HasScanner: false,
            DeliveryCameraIp: device.deliveryCameraIpAddress,
            SimulateStorageSystem:
                device.configuration.general?.simulateStock || device.configuration.general?.simulateProductDelivery || false,
            DisableMonitoring:
                (device.configuration.general?.simulateProductDelivery && device.configuration.general?.simulatePayments) || false,
            DisablePLCConnection: false,
        };

        await meditechDeviceApi.InitialiseDevice(deviceConfiguration);
    };

const getSimulatedRobotStockProductCodes = async (stockData: Domain.VendingMachineStockItem[]) => {
    const stockProductCodes: string[] = [];
    for (const article of stockData) {
        for (const code of article.barcodes) {
            if (!stockProductCodes.includes(code)) {
                stockProductCodes.push(code);
            }
        }
    }

    return stockProductCodes;
};

const getRobotStockProductCodes = async (stockData: DeviceInterface.IStockInformationResponse) => {
    const stockProductCodes: string[] = [];
    for (const article of stockData.Articles) {
        if (!stockProductCodes.includes(article.ArticleCode)) {
            stockProductCodes.push(article.ArticleCode);
        }
    }

    return stockProductCodes;
};

const getCollectStockProductCodes = async (collectStockItems: Domain.CollectStockAndStatuses) => {
    const stockProductCodes: string[] = [];
    for (const locker of collectStockItems) {
        for (const item of locker.items) {
            const codes = productCodesToList(item.productCodes);
            for (const code of codes) {
                if (!stockProductCodes.includes(code)) {
                    stockProductCodes.push(code);
                }
            }
        }
    }

    return stockProductCodes;
};

const getHomeScreenProductIdsForDevice = (device: Domain.DeviceDetails): string[] => {
    const homeScreenProducts = Domain.ensureDeviceHomeScreenProductsV2(device.configuration.homeScreenProducts);
    const stockProductIds: string[] = [];
    for (const category of homeScreenProducts.categories) {
        for (const productId of category.productIds) {
            if (!stockProductIds.includes(productId)) {
                stockProductIds.push(productId);
            }
        }
    }
    return stockProductIds;
};

const initializeVendingMachineDevice =
    (device: Domain.DeviceDetails): ThunkAction =>
    async () => {
        console.log(
            `ON DEVICE ${device.name} / ${device.companyName} / ${device.branchName} (${device.deviceId}), ${device.connectedToLockers ? '+LOCKERS' : ''} ${device.robotDeliveryIsAvailable ? '+ROBOT' : ''}`,
        );

        const deviceConfiguration: DeviceInterface.IDeviceConfiguration = {
            DeviceType: DeviceInterface.IDeviceType.MATIC,
            IsCollect: device.connectedToLockers || false,
            IsMaticSlim: device.screenResolution === '1280x1024',
            HasRobotConnection: device.robotStockIsAvailable,
            RobotIpPort: device.robotUrl,
            RobotStockLocation: device.robotStockLocations,
            RobotZone: device.robotZone,
            RobotDeliveryExit: device.robotOutputDestination,
            RobotTransportTime: device.transportTimeFromRobot,
            PharmacySoftwareIpPort: device.configuration.nextpharmWebSocket,
            HasScanner: device.barcodeScannerIsAvailable,
            DeliveryCameraIp: device.deliveryCameraIpAddress,
            SimulateStorageSystem:
                device.configuration.general?.simulateStock || device.configuration.general?.simulateProductDelivery || false,
            DisableMonitoring:
                (device.configuration.general?.simulateProductDelivery && device.configuration.general?.simulatePayments) || false,
            DisablePLCConnection: false,
        };

        await meditechDeviceApi.InitialiseDevice(deviceConfiguration);
    };

const buildCatalog = async (device: Domain.DeviceDetails, stockProductCodes: string[], stock: Domain.VendingMachineStockItem[]) => {
    const homeScreenProductIds = getHomeScreenProductIdsForDevice(device);
    const goodieBagProductId = device.goodieBagProductId || '';
    const isGoodieBagEnabled = device.isGoodieBagEnabled || false;
    const [catalogBase, productsForStock, homeScreenProducts] = await Promise.all([
        vendingMachineCatalogApi.GetVendingMachineCatalogCategoriesAndBrands(),
        vendingMachineCatalogApi.GetVendingMachineCatalogByProductCodes(getDeviceCountry(device), stockProductCodes),
        vendingMachineCatalogApi.GetVendingMachineCatalogByProductIds(getDeviceCountry(device), [
            ...homeScreenProductIds,
            goodieBagProductId,
        ]),
    ]);

    const catalog = new VendingMachineCatalog(Domain.ensureDeviceHomeScreenProductsV2(device.configuration.homeScreenProducts));
    const goodieBagProduct = [];
    if (isGoodieBagEnabled && !homeScreenProducts.find(product => product.pid === goodieBagProductId) && device.goodieBagProductDetails) {
        goodieBagProduct.push({
            pid: goodieBagProductId,
            pcodes: device.goodieBagProductDetails.productCodes,
            vatr: 0,
            vata: 0,
            cids: device.goodieBagProductDetails.categoryIds,
            bids: [],
            img: {},
            isGoodieBag: true,
            name: device.goodieBagProductDetails.localizedNames,
        });
    }
    await catalog.init({
        ...catalogBase,
        products: [...productsForStock, ...homeScreenProducts, ...goodieBagProduct],
        stock,
    });

    return catalog;
};

const initializeProductCatalog =
    (device: Domain.DeviceDetails): ThunkAction =>
    async dispatch => {
        VendingMachineLog.productCatalogLoad();

        let stockProductCodes: string[] = [];
        let stock: Domain.VendingMachineStockItem[] = [];

        if (device.connectedToLockers) {
            const [collectStockItems, configuration] = await Promise.all([collectApi.GetStockAndStatuses(), collectApi.GetConfiguration()]);

            dispatch(reducerActions.setCollectLockerGroupConfiguration(configuration));
            stockProductCodes = await getCollectStockProductCodes(collectStockItems);

            stock = filterSellableCollectStock(collectStockItems)
                .filter(stockItem => {
                    const module = configuration.modules.find(module => module.id === stockItem.moduleId);
                    if (!module) {
                        return false;
                    }

                    const locker = module.lockers.find(locker => locker.id === stockItem.lockerId);
                    if (!locker) {
                        return false;
                    }

                    return true;
                })
                .map(stockItem => {
                    return {
                        barcodes: productCodesToList(stockItem.items[0].productCodes),
                        quantity: 1,
                        location: {
                            type: 'collect',
                            moduleId: stockItem.moduleId,
                            lockerId: stockItem.lockerId,
                        },
                    };
                });
        }

        if (device.robotStockIsAvailable) {
            if (device.configuration.general?.simulateStock) {
                if (device.connectedToLockers) {
                    stock = [...stock, ...SIMULATED_getRobotStock(PRODUCT_CODES_FOR_SIMULATED_LOCKERS)];
                } else {
                    stock = [...stock, ...SIMULATED_getRobotStock(PRODUCT_CODES_FOR_SIMULATED_STANDALONE_MATIC)];
                }

                stockProductCodes = [...stockProductCodes, ...(await getSimulatedRobotStockProductCodes(stock))];
            } else {
                const stockData = await meditechDeviceApi.GetStockInformation();

                stockProductCodes = [...stockProductCodes, ...(await getRobotStockProductCodes(stockData))];
                const robotStock: Domain.VendingMachineStockItem[] = stockData.Articles.map(article => {
                    return {
                        barcodes: [article.ArticleCode],
                        quantity: parseInt(article.Quantity) || 0,
                        location: {
                            type: 'robot',
                        },
                    };
                });
                stock = [...stock, ...robotStock];
            }
        }

        const catalog = await buildCatalog(device, stockProductCodes, stock);
        dispatch(reducerActions.setCatalog(catalog));

        if (device.robotStockIsAvailable) {
            if (device.configuration.general?.simulateStock) {
                await meditechDeviceApi.SetDisableMonitoring(true);
            } else {
                dispatch(listenForStockChanges(device, catalog));
            }
        }
    };

const listenForStockChanges =
    (device: Domain.DeviceDetails, catalog: VendingMachineCatalog): ThunkAction =>
    async () => {
        meditechDeviceApi.StockEventEmitter.on(DeviceInterface.StockEvent.NeedsRefresh, async () => {
            const renewedStockData = await meditechDeviceApi.GetStockInformation();

            const stockProductCodes: string[] = [];
            for (const article of renewedStockData.Articles) {
                if (!stockProductCodes.includes(article.ArticleCode) && parseInt(article.Quantity) > 0) {
                    stockProductCodes.push(article.ArticleCode);
                }
            }
            const [catalogBase, productsForStock] = await Promise.all([
                vendingMachineCatalogApi.GetVendingMachineCatalogCategoriesAndBrands(),
                vendingMachineCatalogApi.GetVendingMachineCatalogByProductCodes(getDeviceCountry(device), stockProductCodes),
            ]);

            catalog.updateStock({
                ...catalogBase,
                products: productsForStock,
                stock: renewedStockData.Articles.map(article => {
                    return {
                        barcodes: [article.ArticleCode],
                        quantity: parseInt(article.Quantity) || 0,
                        location: {
                            type: 'robot',
                        },
                    };
                }),
            });
        }).on(DeviceInterface.StockEvent.StockMutation, async (stockMutation: DeviceInterface.IStockMutationEvent) => {
            const stockProductCodes: string[] = [];
            for (const article of stockMutation.Articles) {
                if (!stockProductCodes.includes(article.ArticleCode) && article.Quantity > 0) {
                    stockProductCodes.push(article.ArticleCode);
                }
            }
            const productsForStock = await vendingMachineCatalogApi.GetVendingMachineCatalogByProductCodes(
                getDeviceCountry(device),
                stockProductCodes,
            );

            catalog.updateStock({
                products: productsForStock,
                stock: stockMutation.Articles.map(article => {
                    return {
                        barcodes: [article.ArticleCode],
                        quantity: article.Quantity || 0,
                        location: {
                            type: 'robot',
                        },
                    };
                }),
            });
        });
    };

export const initializeVendingMachine =
    (device: Domain.DeviceDetails): ThunkAction =>
    async dispatch => {
        const standbyMode = device.configuration.general?.standbyMode || 'off';
        console.log('STANDBY MODE:', standbyMode);

        if (standbyMode === 'disable-everything') {
            return;
        }

        if (device.type === 'vending-machine') {
            VendingMachineLog.resetShoppingSession();

            await dispatch(initializeVendingMachineDevice(device));
            await dispatch(initializeProductCatalog(device));
            meditechDeviceApi.DeviceEventEmitter.on(DeviceInterface.DeviceEvent.EmergencyCircuitInterrupted, () => {
                deviceApi.CreateRobotEmergencyStopNotification({
                    deviceId: device.deviceId,
                });
            });
        } else {
            await dispatch(initializeVision(device));
        }
    };

export function filterSellableCollectStock(stockItems: Domain.CollectStockAndStatuses): Domain.CollectStockAndStatuses {
    return stockItems.filter(stockItem => {
        if (stockItem.isEnabled === false) {
            return false;
        }

        // we can't sell from lockers with more than one item in them
        if (stockItem.items.length !== 1) {
            return false;
        }

        // we can't sell reserved items
        if (stockItem.pickupId) {
            return false;
        }

        return true;
    });
}

export function filterPickupsCollectStock(stockItems: Domain.CollectStockAndStatuses): Domain.CollectStockAndStatuses {
    return stockItems.filter(stockItem => {
        if (stockItem.isEnabled === false) {
            return false;
        }

        // include only reserved items
        if (!stockItem.pickupId) {
            return false;
        }

        return true;
    });
}

export const refreshCollectStock = (): ThunkAction => async (_1, getState) => {
    const state = getState();
    const device = selectDevice(state);
    const catalog = selectCatalog(state);
    const configuration = selectCollectLockerGroupConfiguration(state) || {
        modules: [],
    };

    if (device && catalog && device.type === 'vending-machine' && device.connectedToLockers) {
        console.info('refreshCollectStock');
        const collectStockItems = await collectApi.GetStockAndStatuses();
        // we do not support more than one item per locker
        const newStock: Domain.VendingMachineStockItem[] = collectStockItems
            .filter(stockItem => {
                if (stockItem.isEnabled === false) {
                    return false;
                }

                if (stockItem.items.length === 0) {
                    return false;
                }

                const module = configuration.modules.find(module => module.id === stockItem.moduleId);
                if (!module) {
                    return false;
                }

                const locker = module.lockers.find(locker => locker.id === stockItem.lockerId);
                if (!locker) {
                    return false;
                }

                return true;
            })
            .map(stockItem => {
                return {
                    barcodes: productCodesToList(stockItem.items[0].productCodes),
                    quantity: 1,
                    location: {
                        type: 'collect',
                        moduleId: stockItem.moduleId,
                        lockerId: stockItem.lockerId,
                    },
                };
            });

        const stockProductCodes: string[] = [];
        for (const item of newStock) {
            for (const code of item.barcodes) {
                if (!stockProductCodes.includes(code)) {
                    stockProductCodes.push(code);
                }
            }
        }
        const productsForStock = await vendingMachineCatalogApi.GetVendingMachineCatalogByProductCodes(
            getDeviceCountry(device),
            stockProductCodes,
        );
        catalog.updateStock({
            products: productsForStock,
            stock: newStock,
        });
        VendingMachineLog.refreshedCollectStock();
    }
};
