import { Pagination, Sorting, Tag, TagsPage, UpdateTagPayload, RemoveTagPayload, TagContentPayload, OptionalOwnershipIds } from '@/Domain';

import Endpoint from './Endpoint';

export default class TagsEndpoint extends Endpoint {
    public async GetTags(
        ownershipIds: OptionalOwnershipIds,
        pagination: Pagination,
        sorting: Sorting,
        filters?: any,
        search?: string,
    ): Promise<TagsPage> {
        const { branchId, companyId } = ownershipIds;
        const url = branchId && companyId ? `/tag/branch/${branchId}/overview` : `/tag/company/${companyId}/overview`;
        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/TagsPage', response);

        return response;
    }

    public async GetTagDetails(tagId: string): Promise<Tag> {
        const response = await this.client.get({
            url: `/tag/${tagId}/details`,
        });

        this.guardResponseSchema('#/definitions/Tag', response);

        return response;
    }

    public async TagContent(payload: TagContentPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/TagContent',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Tag\\ContentTag\\TagContent\\TagContent',
                payload,
            },
        });
    }

    public async UpdateTag(payload: UpdateTagPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Tag\\UpdateTag\\UpdateTag',
                payload,
            },
        });
    }

    public async SearchTags(locale: string, searchOnLabel: string): Promise<string[]> {
        const response = await this.client.get({
            url: `/tag/autocomplete/${locale}/${searchOnLabel}`,
        });

        this.guardResponseSchema('#/definitions/SearchTagsResponse', response);

        return response;
    }
    public async RemoveTag(payload: RemoveTagPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Tag\\RemoveTag\\RemoveTag',
                payload,
            },
        });
    }
}
