import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { deviceGoodieBagApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

import { selectGlobalSelectedDevice } from './globalSelectionState';

export type GoodieBagPickupProps = keyof Domain.GoodieBagPickup;

export const pageSize = 999;

export const selectGoodieBagList: Selector<RootState, Domain.GoodieBagPickup[]> = state =>
    state.device.vendingMachineGoodieBagPickUpCodesOverview.goodieBagPickups;
export const setGoodieBagPickups = createAction(
    '@vendingMachine/goodieBagPickupCodesOverview/setGoodieBagPickups',
    withPayloadType<Domain.GoodieBagPickup[]>(),
);

export const selectors = makeSelectors<Domain.GoodieBagPickup, GoodieBagPickupProps>({
    getState: rootState => rootState.device.vendingMachineGoodieBagPickUpCodesOverview,
});

export const reducerActions = makeReducerActions<Domain.GoodieBagPickup, GoodieBagPickupProps>({
    reducerPrefix: '@vendingMachine/goodieBagPickupCodesOverview',
});
export const loadGoodieBagPickUps = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectGlobalSelectedDevice(state);
    console.log('device', device);
    if (!device) {
        return;
    }
    const filters = selectors.selectFilters(state) || {};

    const deviceGoodieBagResponse = await deviceGoodieBagApi.GetDeviceGoodieBagPickups(device.deviceId, filters);
    await dispatch(setGoodieBagPickups(deviceGoodieBagResponse.items));
};

export const baseActions = makeActions<Domain.GoodieBagPickup, GoodieBagPickupProps>({
    dataTableSaveKey: 'goodieBagPickupCodesOverview-v1',
    pageSize,
    defaultSorting: {
        field: 'createdAt',
        direction: 'descending',
    },
    loadApi: () => Promise.resolve({ total: 0, items: [], pagination: { page: 1, size: pageSize } }),
    getBaseUrl: () => '/vending-machines/goodie-bag/pickup-codes-overview',
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadGoodieBagPickUps())]);
        },
};

export const vendingMachineGoodieBagOverviewReducer = makeReducer<
    Domain.GoodieBagPickup,
    GoodieBagPickupProps,
    {
        goodieBagPickups: Domain.GoodieBagPickup[];
    }
>({
    pageSize,
    reducerActions,
    defaultSorting: {
        field: 'createdAt',
        direction: 'descending',
    },
    extraCases: builder =>
        builder.addCase(setGoodieBagPickups, (state, action) => {
            state.goodieBagPickups = action.payload;
        }),
});
