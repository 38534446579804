import { Domain } from 'api';
import { Actions as CanvasActions } from 'editor-canvas';

import {
    AddProductToShelfPayload,
    Frame,
    ImageFrameCrop,
    PlacedProductAlignment,
    LabelStyleShape,
    Shelf,
    ShelfStyle,
    TextStyle,
    WallBackgroundType,
    Stock,
} from '../types';
import { EyedropperChangeHandlers } from './state';

export const FORCE_REFRESH = 'FORCE_REFRESH';
export const SET_AVAILABLE_PRODUCTS = 'SET_AVAILABLE_PRODUCTS';
export const SET_STOCK = 'SET_STOCK';
export const UPDATE_AVAILABLE_PRODUCT = 'UPDATE_AVAILABLE_PRODUCT';
export const SET_SHELVES = 'SET_SHELVES';
export const SET_SHELF_Y_OFFSET = 'SET_SHELF_Y_OFFSET';
export const ADD_PRODUCTS_SHELF = 'ADD_PRODUCTS_SHELF';
export const ADD_CUSTOM_SHELF = 'ADD_CUSTOM_SHELF';
export const REMOVE_SHELF = 'REMOVE_SHELF';
export const CLEAR_SHELF = 'CLEAR_SHELF';
export const SPLIT_SHELF = 'SPLIT_SHELF';
export const SET_DRAGGING_SHELF = 'SET_DRAGGING_SHELF';
export const SET_DRAGGED_PRODUCT = 'SET_DRAGGED_PRODUCT';
export const SET_DRAGGED_PRODUCT_POSITION = 'SET_DRAGGED_PRODUCT_POSITION';
export const SET_DRAGGED_PRODUCT_DROPPED_FLAG = 'SET_DRAGGED_PRODUCT_DROPPED_FLAG';
export const ADD_PRODUCT_TO_SHELF = 'ADD_PRODUCT_TO_SHELF';
export const REMOVE_PRODUCT_FROM_SHELF = 'REMOVE_PRODUCT_FROM_SHELF';
export const SET_RESIZED_PRODUCT = 'SET_RESIZED_PRODUCT';
export const SET_PLACED_PRODUCT_TOP_OFFSET = 'SET_PLACED_PRODUCT_TOP_OFFSET';
export const SET_PLACED_PRODUCT_LEFT_OFFSET = 'SET_PLACED_PRODUCT_LEFT_OFFSET';
export const SET_PLACED_PRODUCT_RIGHT_OFFSET = 'SET_PLACED_PRODUCT_RIGHT_OFFSET';
export const SET_PLACED_PRODUCT_SIZE = 'SET_PLACED_PRODUCT_SIZE';
export const SHOW_PLACED_PRODUCT_SETTINGS_DIALOG = 'SHOW_PLACED_PRODUCT_SETTINGS_DIALOG';
export const HIDE_PLACED_PRODUCT_SETTINGS_DIALOG = 'HIDE_PLACED_PRODUCT_SETTINGS_DIALOG';
export const SHOW_GENERAL_SETTINGS = 'SHOW_GENERAL_SETTINGS';
export const HIDE_GENERAL_SETTINGS = 'HIDE_GENERAL_SETTINGS';
export const SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED = 'SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED';
export const SET_PLACED_PRODUCT_ITEMS_LIMIT = 'SET_PLACED_PRODUCT_ITEMS_LIMIT';
export const SET_PLACED_PRODUCT_SCALE = 'SET_PLACED_PRODUCT_SCALE';
export const SET_PLACED_PRODUCT_ALIGN = 'SET_PLACED_PRODUCT_ALIGN';
export const SET_PLACED_PRODUCT_X_SPACING = 'SET_PLACED_PRODUCT_X_SPACING';
export const SET_PLACED_PRODUCT_Y_SPACING = 'SET_PLACED_PRODUCT_Y_SPACING';
export const SET_PLACED_PRODUCT_IMAGE_ID = 'SET_PLACED_PRODUCT_IMAGE_ID';
export const SET_PLACED_PRODUCT_MEDIA_ITEM_ID = 'SET_PLACED_PRODUCT_MEDIA_ITEM_ID';
export const SET_PLACED_PRODUCT_SHOW_PRICE = 'SET_PLACED_PRODUCT_SHOW_PRICE';
export const SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE = 'SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE';
export const TOGGLE_PLACED_PRODUCTS_SHOW_PRICE = 'TOGGLE_PLACED_PRODUCTS_SHOW_PRICE';
export const TOGGLE_FORCE_HIDE_STOCK_DISPLAY = 'TOGGLE_FORCE_HIDE_STOCK_DISPLAY';
export const SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE = 'SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE';
export const SET_DRAGGED_PLACED_PRODUCT = 'SET_DRAGGED_PLACED_PRODUCT';
export const SET_SHELF_STYLE = 'SET_SHELF_STYLE';
export const SET_SHELF_STYLE_HEADER_COLOR = 'SET_SHELF_STYLE_HEADER_COLOR';
export const SET_SHELF_STYLE_BACKGROUND_TYPE = 'SET_SHELF_STYLE_BACKGROUND_TYPE';
export const SET_SHELF_STYLE_BACKGROUND_COLOR = 'SET_SHELF_STYLE_BACKGROUND_COLOR';
export const SET_SHELF_STYLE_BACKGROUND_MEDIA_ITEM_ID = 'SET_SHELF_STYLE_BACKGROUND_MEDIA_ITEM_ID';
export const SET_SHELF_STYLE_PRICE_LABEL_SHAPE = 'SET_SHELF_STYLE_PRICE_LABEL_SHAPE';
export const SET_SHELF_STYLE_PRICE_LABEL_TEXT = 'SET_SHELF_STYLE_PRICE_LABEL_TEXT';
export const SET_SHELF_STYLE_PRICE_LABEL_PROMO_TEXT = 'SET_SHELF_STYLE_PRICE_LABEL_PROMO_TEXT';
export const SET_SHELF_STYLE_PRICE_LABEL_BACKGROUND_COLOR = 'SET_SHELF_STYLE_PRICE_LABEL_BACKGROUND_COLOR';
export const SET_SHELF_STYLE_PRICE_LABEL_BORDER_COLOR = 'SET_SHELF_STYLE_PRICE_LABEL_BORDER_COLOR';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR';
export const SET_FRAME_TYPE = 'SET_FRAME_TYPE';
export const SET_FRAME_TEXT = 'SET_FRAME_TEXT';
export const SET_FRAME_HTML = 'SET_FRAME_HTML';
export const SET_FRAME_MEDIA_ITEM_ID = 'SET_FRAME_MEDIA_ITEM_ID';
export const SET_FRAME_IMAGE_CROP = 'SET_FRAME_IMAGE_CROP';
export const SET_FRAME_HOTSPOT_PRODUCT_ID = 'SET_FRAME_HOTSPOT_PRODUCT_ID';
export const SET_EYEDROPPER_ENABLED = 'SET_EYEDROPPER_ENABLED';
export const SET_EYEDROPPER_TOOL_FRAME_COORDINATES = 'SET_EYEDROPPER_TOOL_FRAME_COORDINATES';
export const SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN = 'SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN';
export const SET_SHELF_STYLE_IN_STOCK_LABEL_TEXT = 'SET_SHELF_STYLE_IN_STOCK_LABEL_TEXT';
export const SET_SHELF_STYLE_IN_STOCK_LABEL_BACKGROUND_COLOR = 'SET_SHELF_STYLE_IN_STOCK_LABEL_BACKGROUND_COLOR';
export const SET_SHELF_STYLE_IN_STOCK_LABEL_BORDER_COLOR = 'SET_SHELF_STYLE_IN_STOCK_LABEL_BORDER_COLOR';
export const SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_TEXT = 'SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_TEXT';
export const SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BACKGROUND_COLOR = 'SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BACKGROUND_COLOR';
export const SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BORDER_COLOR = 'SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BORDER_COLOR';

interface ForceRefreshAction {
    type: typeof FORCE_REFRESH;
}

interface SetAvailableProductsAction {
    type: typeof SET_AVAILABLE_PRODUCTS;
    availableProducts: Domain.SlideshowProduct[];
}

interface SetStockAction {
    type: typeof SET_STOCK;
    stock: Stock;
}

interface UpdateAvailableProductAction {
    type: typeof UPDATE_AVAILABLE_PRODUCT;
    availableProduct: Domain.SlideshowProduct;
}

interface SetShelvesAction {
    type: typeof SET_SHELVES;
    shelves: Shelf[];
}

interface SplitShelfAction {
    type: typeof SPLIT_SHELF;
    shelfId: number;
    height: number;
}

interface SetShelfYOffsetAction {
    type: typeof SET_SHELF_Y_OFFSET;
    shelfId: number;
    offset: number;
    position: 'self' | 'below' | 'above';
}

interface AddProductsShelfAction {
    type: typeof ADD_PRODUCTS_SHELF;
    shelfId: number;
}

interface AddCustomShelfAction {
    type: typeof ADD_CUSTOM_SHELF;
    shelfId: number;
}

interface ClearShelfAction {
    type: typeof CLEAR_SHELF;
    shelfId: number;
}

interface RemoveShelfAction {
    type: typeof REMOVE_SHELF;
    shelfId: number;
}

interface SetDraggingShelfAction {
    type: typeof SET_DRAGGING_SHELF;
    draggingShelf: boolean;
}

interface SetDraggedProductAction {
    type: typeof SET_DRAGGED_PRODUCT;
    draggedProduct?: Domain.SlideshowProduct;
}

interface SetDraggedProductPositionAction {
    type: typeof SET_DRAGGED_PRODUCT_POSITION;
    x: number;
    y: number;
}

interface SetDraggedProductDroppedFlagAction {
    type: typeof SET_DRAGGED_PRODUCT_DROPPED_FLAG;
    dropped: boolean;
}

type AddProductToShelfAction = {
    type: typeof ADD_PRODUCT_TO_SHELF;
} & AddProductToShelfPayload;

interface RemoveProductFromShelfAction {
    type: typeof REMOVE_PRODUCT_FROM_SHELF;
    shelfId: number;
    placedProductId: number;
}

interface SetResizedProductAction {
    type: typeof SET_RESIZED_PRODUCT;
    resizedProductId?: number;
}

interface SetPlacedProductTopOffsetAction {
    type: typeof SET_PLACED_PRODUCT_TOP_OFFSET;
    shelfId: number;
    placedProductId: number;
    offset: number;
}

interface SetPlacedProductLeftOffsetAction {
    type: typeof SET_PLACED_PRODUCT_LEFT_OFFSET;
    shelfId: number;
    placedProductId: number;
    offset: number;
}

interface SetPlacedProductRightOffsetAction {
    type: typeof SET_PLACED_PRODUCT_RIGHT_OFFSET;
    shelfId: number;
    placedProductId: number;
    offset: number;
}

interface SetPlacedProductSizeAction {
    type: typeof SET_PLACED_PRODUCT_SIZE;
    shelfId: number;
    placedProductId: number;
    scale: number;
    x: number;
    width: number;
    height: number;
}

interface ShowPlacedProductSettingsDialogAction {
    type: typeof SHOW_PLACED_PRODUCT_SETTINGS_DIALOG;
    placedProductId: number;
}

interface HidePlacedProductSettingsDialogAction {
    type: typeof HIDE_PLACED_PRODUCT_SETTINGS_DIALOG;
}

interface ShowGeneralSettingsAction {
    type: typeof SHOW_GENERAL_SETTINGS;
}

interface HideGeneralSettingsAction {
    type: typeof HIDE_GENERAL_SETTINGS;
}

interface SetPlacedProductItemsLimitEnabledAction {
    type: typeof SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED;
    shelfId: number;
    placedProductId: number;
    enabled: boolean;
}

interface SetPlacedProductItemsLimitAction {
    type: typeof SET_PLACED_PRODUCT_ITEMS_LIMIT;
    shelfId: number;
    placedProductId: number;
    limit: number;
}

interface SetPlacedProductScaleAction {
    type: typeof SET_PLACED_PRODUCT_SCALE;
    shelfId: number;
    placedProductId: number;
    scale: number;
}

interface SetPlacedProductAlignAction {
    type: typeof SET_PLACED_PRODUCT_ALIGN;
    shelfId: number;
    placedProductId: number;
    align: PlacedProductAlignment;
}

interface SetPlacedProductXSpacingAction {
    type: typeof SET_PLACED_PRODUCT_X_SPACING;
    shelfId: number;
    placedProductId: number;
    spacing: number;
}

interface SetPlacedProductYSpacingAction {
    type: typeof SET_PLACED_PRODUCT_Y_SPACING;
    shelfId: number;
    placedProductId: number;
    spacing: number;
}

interface SetPlacedProductImageIdAction {
    type: typeof SET_PLACED_PRODUCT_IMAGE_ID;
    shelfId: number;
    placedProductId: number;
    imageId: string;
}

interface SetPlacedProductMediaItemIdAction {
    type: typeof SET_PLACED_PRODUCT_MEDIA_ITEM_ID;
    shelfId: number;
    placedProductId: number;
    mediaItemId?: string;
}

interface SetPlacedProductShowPriceAction {
    type: typeof SET_PLACED_PRODUCT_SHOW_PRICE;
    shelfId: number;
    placedProductId: number;
    showPrice: boolean;
}

interface SetPlacedProductHideOriginalPriceAction {
    type: typeof SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE;
    shelfId: number;
    placedProductId: number;
    hideOriginalPrice: boolean;
}

interface TogglePlacedProductsShowPriceAction {
    type: typeof TOGGLE_PLACED_PRODUCTS_SHOW_PRICE;
    setAllTo: boolean;
}

interface ToggleForceHideStockDisplayAction {
    type: typeof TOGGLE_FORCE_HIDE_STOCK_DISPLAY;
}

interface SetPlacedProductEnableCustomPriceStyleAction {
    type: typeof SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE;
    shelfId: number;
    placedProductId: number;
    enableCustomPriceStyling: boolean;
}

interface SetDraggedPlacedProductAction {
    type: typeof SET_DRAGGED_PLACED_PRODUCT;
    draggedProduct?: Domain.SlideshowProduct;
    placedProductId?: number;
}

interface SetShelfStyleAction {
    type: typeof SET_SHELF_STYLE;
    style: ShelfStyle;
}

interface SetShelfStyleHeaderColorAction {
    type: typeof SET_SHELF_STYLE_HEADER_COLOR;
    headerColor?: string;
}

interface SetShelfStyleBackgroundTypeAction {
    type: typeof SET_SHELF_STYLE_BACKGROUND_TYPE;
    backgroundType: WallBackgroundType;
}

interface SetShelfStyleBackgroundColorAction {
    type: typeof SET_SHELF_STYLE_BACKGROUND_COLOR;
    backgroundColor: string;
}

interface SetShelfStyleBackgroundMediaItemIdAction {
    type: typeof SET_SHELF_STYLE_BACKGROUND_MEDIA_ITEM_ID;
    backgroundMediaItemId: string;
    backgroundVideoDuration?: number;
}

interface SetShelfStylePriceLabelShapeAction {
    type: typeof SET_SHELF_STYLE_PRICE_LABEL_SHAPE;
    shape: LabelStyleShape;
    promo?: boolean;
}

interface SetShelfStylePriceLabelTextAction {
    type: typeof SET_SHELF_STYLE_PRICE_LABEL_TEXT;
    text: TextStyle;
    promo?: boolean;
}

interface SetShelfStylePriceLabelPromoTextAction {
    type: typeof SET_SHELF_STYLE_PRICE_LABEL_PROMO_TEXT;
    promoText: TextStyle;
}

interface SetShelfStylePriceLabelBackgroundColorAction {
    type: typeof SET_SHELF_STYLE_PRICE_LABEL_BACKGROUND_COLOR;
    backgroundColor: string;
    promo?: boolean;
}

interface SetShelfStylePriceLabelBorderColorAction {
    type: typeof SET_SHELF_STYLE_PRICE_LABEL_BORDER_COLOR;
    borderColor: string;
    promo?: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelShapeAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE;
    shelfId: number;
    placedProductId: number;
    shape: LabelStyleShape;
    promo?: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelTextAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT;
    shelfId: number;
    placedProductId: number;
    text: TextStyle;
    promo?: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelPromoTextAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT;
    shelfId: number;
    placedProductId: number;
    promoText: TextStyle;
}

interface SetPlacedProductCustomPriceStyleLabelBackgroundColorAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR;
    shelfId: number;
    placedProductId: number;
    backgroundColor: string;
    promo?: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelBorderColorAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR;
    shelfId: number;
    placedProductId: number;
    borderColor: string;
    promo?: boolean;
}

interface SetFrameTypeAction {
    type: typeof SET_FRAME_TYPE;
    frameId: string;
    frameType: Frame['type'];
}

interface SetFrameTextAction {
    type: typeof SET_FRAME_TEXT;
    frameId: string;
    text?: string;
    textStyle: TextStyle;
}

interface SetFrameHTMLAction {
    type: typeof SET_FRAME_HTML;
    frameId: string;
    html?: string;
}

interface SetFrameMediaItemIdAction {
    type: typeof SET_FRAME_MEDIA_ITEM_ID;
    frameId: string;
    mediaItemId?: string;
    videoDuration?: number;
}

interface SetFrameImageCropAction {
    type: typeof SET_FRAME_IMAGE_CROP;
    frameId: string;
    imageCrop?: ImageFrameCrop;
}

interface SetFrameHotSpotProductIdAction {
    type: typeof SET_FRAME_HOTSPOT_PRODUCT_ID;
    frameId: string;
    productId?: string;
}

interface SetEyedropperEnabled {
    type: typeof SET_EYEDROPPER_ENABLED;
    eyedropperEnabled: boolean;
    changeHandlers?: EyedropperChangeHandlers;
}

interface SetEyedropperToolFrameCoordinates {
    type: typeof SET_EYEDROPPER_TOOL_FRAME_COORDINATES;
    frameId?: string;
    shelfId?: number;
    placedProductId?: number;
    x: number;
    y: number;
    w: number;
    h: number;
}

interface SetEyedropperToolFrameMoseIsDown {
    type: typeof SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN;
    frameId?: string;
    shelfId?: number;
    placedProductId?: number;
    isDown: boolean;
}

interface SetShelfStyleInStockLabelTextAction {
    type: typeof SET_SHELF_STYLE_IN_STOCK_LABEL_TEXT;
    text: TextStyle;
}

interface SetShelfStyleInStockLabelBackgroundColorAction {
    type: typeof SET_SHELF_STYLE_IN_STOCK_LABEL_BACKGROUND_COLOR;
    backgroundColor: string;
}

interface SetShelfStyleInStockLabelBorderColorAction {
    type: typeof SET_SHELF_STYLE_IN_STOCK_LABEL_BORDER_COLOR;
    borderColor: string;
}

interface SetShelfStyleOutOfStockLabelTextAction {
    type: typeof SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_TEXT;
    text: TextStyle;
}

interface SetShelfStyleOutOfStockLabelBackgroundColorAction {
    type: typeof SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BACKGROUND_COLOR;
    backgroundColor: string;
}

interface SetShelfStyleOutOfStockLabelBorderColorAction {
    type: typeof SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BORDER_COLOR;
    borderColor: string;
}

export { Actions as CanvasActions } from 'editor-canvas';

export type ActionTypes =
    | CanvasActions.ActionTypes
    | ForceRefreshAction
    | SetAvailableProductsAction
    | SetStockAction
    | UpdateAvailableProductAction
    | SetShelvesAction
    | SetShelfYOffsetAction
    | AddProductsShelfAction
    | AddCustomShelfAction
    | ClearShelfAction
    | RemoveShelfAction
    | SplitShelfAction
    | SetDraggingShelfAction
    | SetDraggedProductAction
    | SetDraggedProductPositionAction
    | SetDraggedProductDroppedFlagAction
    | AddProductToShelfAction
    | RemoveProductFromShelfAction
    | SetResizedProductAction
    | SetPlacedProductTopOffsetAction
    | SetPlacedProductLeftOffsetAction
    | SetPlacedProductRightOffsetAction
    | SetPlacedProductSizeAction
    | SetPlacedProductShowPriceAction
    | TogglePlacedProductsShowPriceAction
    | ToggleForceHideStockDisplayAction
    | SetPlacedProductEnableCustomPriceStyleAction
    | ShowPlacedProductSettingsDialogAction
    | HidePlacedProductSettingsDialogAction
    | ShowGeneralSettingsAction
    | HideGeneralSettingsAction
    | SetPlacedProductItemsLimitEnabledAction
    | SetPlacedProductItemsLimitAction
    | SetPlacedProductScaleAction
    | SetPlacedProductAlignAction
    | SetPlacedProductXSpacingAction
    | SetPlacedProductYSpacingAction
    | SetPlacedProductImageIdAction
    | SetPlacedProductMediaItemIdAction
    | SetDraggedPlacedProductAction
    | SetShelfStyleAction
    | SetShelfStyleHeaderColorAction
    | SetShelfStyleBackgroundTypeAction
    | SetShelfStyleBackgroundColorAction
    | SetShelfStyleBackgroundMediaItemIdAction
    | SetShelfStylePriceLabelShapeAction
    | SetShelfStylePriceLabelTextAction
    | SetShelfStylePriceLabelPromoTextAction
    | SetShelfStylePriceLabelBackgroundColorAction
    | SetShelfStylePriceLabelBorderColorAction
    | SetPlacedProductHideOriginalPriceAction
    | SetPlacedProductCustomPriceStyleLabelShapeAction
    | SetPlacedProductCustomPriceStyleLabelTextAction
    | SetPlacedProductCustomPriceStyleLabelPromoTextAction
    | SetPlacedProductCustomPriceStyleLabelBackgroundColorAction
    | SetPlacedProductCustomPriceStyleLabelBorderColorAction
    | SetFrameTypeAction
    | SetFrameTextAction
    | SetFrameHTMLAction
    | SetFrameMediaItemIdAction
    | SetFrameImageCropAction
    | SetFrameHotSpotProductIdAction
    | SetEyedropperEnabled
    | SetEyedropperToolFrameCoordinates
    | SetEyedropperToolFrameMoseIsDown
    | SetShelfStyleInStockLabelTextAction
    | SetShelfStyleInStockLabelBackgroundColorAction
    | SetShelfStyleInStockLabelBorderColorAction
    | SetShelfStyleOutOfStockLabelTextAction
    | SetShelfStyleOutOfStockLabelBackgroundColorAction
    | SetShelfStyleOutOfStockLabelBorderColorAction;
