import { Domain, RoomService } from 'api';

type StateChangeMessageHandler = (state: Domain.NightHatchStateMessage['state']) => void;
type HardwareActionMessageHandler = (message: Domain.NightHatchHardwareActionMessage) => void;
type RobotProductDeliveryActionMessageHandler = (message: Domain.NightHatchRobotProductDeliveryActionMessage) => void;
type ProductStockInquiryMessageHandler = (message: Domain.NightHatchProductStockInquiryMessage) => void;
type StartTerminalTransactionActionMessageHandler = (message: Domain.NightHatchStartTerminalTransactionActionMessage) => void;
type StopTerminalTransactionActionMessageHandler = (message: Domain.NightHatchStopTerminalTransactionActionMessage) => void;
type InitiateVideoCallMessageHandler = (message: Domain.NightHatchInitiateVideoCallMessage) => void;
type GetHatchPhotoMessageHandler = (message: Domain.NightHatchGetHatchPhotoMessage) => void;
type GetScreenPhotoMessageHandler = (message: Domain.NightHatchGetScreenPhotoMessage) => void;
type OpenCollectLockerMessageHandler = (message: Domain.NightHatchOpenCollectLockerMessage) => void;
type RequestIdScanMessageHandler = (message: Domain.NightHatchRequestIdScanMessage) => void;

export class ClientSideApi {
    private readonly tunnel: RoomService.NightHatchTunnel;
    private stateChangeMessageHandler: StateChangeMessageHandler;
    private hardwareActionMessageHandler: HardwareActionMessageHandler;
    private robotProductDeliveryActionMessageHandler: RobotProductDeliveryActionMessageHandler;
    private productStockInquiryMessageHandler: ProductStockInquiryMessageHandler;
    private choosePaymentMethodActionMessageHandler: () => void;

    private startTerminalTransactionActionMessageHandler: StartTerminalTransactionActionMessageHandler;
    private stopTerminalTransactionActionMessageHandler: StopTerminalTransactionActionMessageHandler;
    private initiateVideoCallMessageHandler: InitiateVideoCallMessageHandler;
    private getHatchPhotoMessageHandler: GetHatchPhotoMessageHandler;
    private getScreenPhotoMessageHandler: GetScreenPhotoMessageHandler;
    private openCollectLockerMessageHandler: OpenCollectLockerMessageHandler;
    private requestIdScanMessageHandler: RequestIdScanMessageHandler;

    public constructor(tunnel: RoomService.NightHatchTunnel) {
        this.tunnel = tunnel;
        this.tunnel.addEventListener('message', this.handleMessage);
    }

    public sendHardwareStatus = (hardwareStatus: Domain.NightHatchHardwareStatus) => {
        this.tunnel.send({
            tunnel: true,
            type: 'hardwareStatus',
            status: hardwareStatus,
        });
    };

    public sendRobotDeliveryStatus = (deliveryStatus: Domain.NightHatchRobotProductDeliveryStatus) => {
        this.tunnel.send({
            tunnel: true,
            type: 'robotProductDeliveryStatus',
            deliveryStatus,
        });
    };

    public sendSimulatedTransactionStatus = (transactionStatus: boolean) => {
        this.tunnel.send({
            tunnel: true,
            type: 'simulatedTransactionStatus',
            transactionStatus,
        });
    };

    public sendGetHatchPhotoData = (data: string) => {
        this.tunnel.send({
            tunnel: true,
            type: 'getHatchPhotoData',
            data,
        });
    };

    public sendGetScreenPhotoData = (data: string) => {
        this.tunnel.send({
            tunnel: true,
            type: 'getScreenPhotoData',
            data,
        });
    };

    public sendScannedBarcode = (barcode: string) => {
        this.tunnel.send({
            tunnel: true,
            type: 'scannedBarcode',
            barcode,
        });
    };

    public sendStockUpdate = (stockUpdate: Domain.NightHatchCartProductsStockUpdate) => {
        this.tunnel.send({
            tunnel: true,
            type: 'cartProductsStockUpdate',
            stockUpdate,
        });
    };

    public sendProductStockInquiryResults = (results: Domain.NightHatchProductStockInquiryResults) => {
        this.tunnel.send({
            tunnel: true,
            type: 'productStockInquiryResults',
            results,
        });
    };

    public sendConfirmCustomerDeliveryAction = () => {
        this.tunnel.send({
            tunnel: true,
            type: 'confirmCustomerDeliveryAction',
        });
    };
    public sendChoosePaymentOptionAction = (paymentMethod: Domain.DeviceTransactionPaymentMethod) => {
        this.tunnel.send({
            tunnel: true,
            type: 'choosePaymentOptionAction',
            paymentMethod,
        });
    };
    public onStateChangeMessage(handler: StateChangeMessageHandler) {
        this.stateChangeMessageHandler = handler;
    }

    public onHardwareActionMessage(handler: HardwareActionMessageHandler) {
        this.hardwareActionMessageHandler = handler;
    }

    public onRobotProductDeliveryActionMessage(handler: RobotProductDeliveryActionMessageHandler) {
        this.robotProductDeliveryActionMessageHandler = handler;
    }

    public onProductStockInquiryMessage(handler: ProductStockInquiryMessageHandler) {
        this.productStockInquiryMessageHandler = handler;
    }

    public onStartTerminalTransactionActionMessage(handler: StartTerminalTransactionActionMessageHandler) {
        this.startTerminalTransactionActionMessageHandler = handler;
    }

    public onChoosePaymentMethodActionMessageHandler(handler: () => void) {
        this.choosePaymentMethodActionMessageHandler = handler;
    }
    public onStopTerminalTransactionActionMessage(handler: StopTerminalTransactionActionMessageHandler) {
        this.stopTerminalTransactionActionMessageHandler = handler;
    }

    public onInitiateVideoCallMessage(handler: InitiateVideoCallMessageHandler) {
        this.initiateVideoCallMessageHandler = handler;
    }

    public onGetHatchPhotoMessage(handler: GetHatchPhotoMessageHandler) {
        this.getHatchPhotoMessageHandler = handler;
    }

    public onGetScreenPhotoMessage(handler: GetScreenPhotoMessageHandler) {
        this.getScreenPhotoMessageHandler = handler;
    }

    public onOpenCollectLockerMessage(handler: OpenCollectLockerMessageHandler) {
        this.openCollectLockerMessageHandler = handler;
    }

    public onRequestIdScanMessage(handler: RequestIdScanMessageHandler) {
        this.requestIdScanMessageHandler = handler;
    }

    private handleMessage = (event: MessageEvent) => {
        const message: Domain.NightHatchTunnelMessage = JSON.parse(event.data);

        if (message.type === 'state' && this.stateChangeMessageHandler) {
            this.stateChangeMessageHandler(message.state);
        } else if (message.type === 'hardwareAction' && this.hardwareActionMessageHandler) {
            this.hardwareActionMessageHandler(message);
        } else if (message.type === 'deliverProductsFromRobot' && this.robotProductDeliveryActionMessageHandler) {
            this.robotProductDeliveryActionMessageHandler(message);
        } else if (message.type === 'productStockInquiry' && this.productStockInquiryMessageHandler) {
            this.productStockInquiryMessageHandler(message);
        } else if (message.type === 'startTerminalTransaction' && this.startTerminalTransactionActionMessageHandler) {
            this.startTerminalTransactionActionMessageHandler(message);
        } else if (message.type === 'choosePaymentOption' && this.choosePaymentMethodActionMessageHandler) {
            this.choosePaymentMethodActionMessageHandler();
        } else if (message.type === 'stopTerminalTransaction' && this.stopTerminalTransactionActionMessageHandler) {
            this.stopTerminalTransactionActionMessageHandler(message);
        } else if (message.type === 'initiateVideoCall' && this.initiateVideoCallMessageHandler) {
            this.initiateVideoCallMessageHandler(message);
        } else if (message.type === 'getHatchPhoto' && this.getHatchPhotoMessageHandler) {
            this.getHatchPhotoMessageHandler(message);
        } else if (message.type === 'getScreenPhoto' && this.getScreenPhotoMessageHandler) {
            this.getScreenPhotoMessageHandler(message);
        } else if (message.type === 'openCollectLocker' && this.openCollectLockerMessageHandler) {
            this.openCollectLockerMessageHandler(message);
        } else if (message.type === 'requestIdScan' && this.requestIdScanMessageHandler) {
            this.requestIdScanMessageHandler(message);
        }
    };

    public close() {
        this.tunnel.removeEventListener('message', this.handleMessage);
    }
}
