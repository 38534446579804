import { Domain } from 'api';
import { computeProductSize } from 'utils';

import { FrameWithMeta, Frame, ProductImageSizes } from '../types';
import computeMaxUsableScale from './computeMaxUsableScale';
import fillProductArea from './fillProductArea';
import getProductImages from './getProductImages';
import getSelectedImage from './getSelectedImage';

export default function computeFrameMetas(
    screenResolution: Domain.DeviceScreenResolution,
    frame: Frame,
    availableProducts: Domain.SlideshowProduct[],
    productImageSizes: ProductImageSizes,
): FrameWithMeta {
    if (frame.type === 'product') {
        const product = availableProducts.find(searchedProduct => searchedProduct.productId === frame.productId);

        if (product) {
            const productImages = getProductImages(product);
            const selectedImage = getSelectedImage(productImages, frame.productMediaType, frame.imageId);
            const imageSize = productImageSizes.get(selectedImage.url);

            if (imageSize) {
                const productSize = computeProductSize(
                    screenResolution,
                    product,
                    selectedImage.url,
                    imageSize,
                    true,
                    frame.placedProduct.version || 0,
                );

                const maxScale = computeMaxUsableScale(frame, productSize, frame.placedProduct.scale || 2);
                let scale = 1;
                if (frame.placedProduct.scale) {
                    scale = frame.placedProduct.scale;
                }
                if (scale > maxScale) {
                    scale = maxScale;
                }

                const boxes = fillProductArea({
                    areaWidth: frame.width,
                    areaHeight: frame.height,
                    productWidth: productSize.width,
                    productHeight: productSize.height,
                    scale,
                    itemsLimit: frame.placedProduct.enableItemsLimit ? frame.placedProduct.itemsLimit : undefined,
                    alignItems: frame.placedProduct.alignItems || 'center',
                    spacingX: frame.placedProduct.spacingX || 0,
                    spacingY: frame.placedProduct.spacingY || 0,
                    bottomOffset: 0,
                });

                return {
                    ...frame,
                    boxes,
                    product,
                };
            }
        }

        return {
            ...frame,
            boxes: [],
        };
    }

    return frame;
}
