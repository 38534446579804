import {
    WebshopOrderDetails,
    UpdateWebshopOrderStatusPayload,
    BackOrderDetails,
    AcceptOrderInReviewPayload,
    RejectOrderInReviewPayload,
    OrderReviewReasons,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopOrderEndpoint extends Endpoint {
    public async GetWebshopOrderDetails(webshopId: string, orderId: string): Promise<WebshopOrderDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/order/${orderId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopOrderDetails', response);

        return response;
    }

    public async UpdateWebshopOrderStatus(payload: UpdateWebshopOrderStatusPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateOrderStatus',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\UpdateOrderStatus\\UpdateOrderStatus',
                payload,
            },
        });
    }

    public async GetBackOrderDetails(webshopId: string, orderId: string): Promise<BackOrderDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/order/${orderId}/back-order-details`,
        });

        this.guardResponseSchema('#/definitions/BackOrderDetails', response);

        return response;
    }

    public async AcceptOrderInReview(payload: AcceptOrderInReviewPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AcceptOrderInReview',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\AcceptOrderInReview\\AcceptOrderInReview',
                payload,
            },
        });
    }

    public async RejectOrderInReview(payload: RejectOrderInReviewPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RejectOrderInReview',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\RejectOrderInReview\\RejectOrderInReview',
                payload,
            },
        });
    }

    public async GetOrderReviewReason(orderId: string): Promise<OrderReviewReasons> {
        const response = await this.client.get({
            url: `/order/${orderId}/review-reasons`,
        });

        this.guardResponseSchema('#/definitions/OrderReviewReasons', response);

        return response;
    }
}
