import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { ordersApi } from '@/api';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

type OrderProps = keyof Domain.BaseOrder;

export const defaultSorting: Domain.Sorting<OrderProps> = {
    field: 'createdAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.BaseOrder, OrderProps>({
    getState: rootState => rootState.deviceOrder.overview,
});

export const reducerActions = makeReducerActions<Domain.BaseOrder, OrderProps>({
    reducerPrefix: '@deviceOrder/overview',
});

export const baseActions = makeActions<Domain.BaseOrder, OrderProps>({
    dataTableSaveKey: 'deviceOrdersOverview-v6',
    loadApi: options => {
        if (options.globallySelectedDeviceId === undefined) {
            return;
        }

        return ordersApi.GetOrders(
            options.pagination,
            options.sorting,
            {
                ...(options.filters as object),
                salesChannelId: options.filters.deviceId ? options.filters.deviceId : options.globallySelectedDeviceId,
                salesChannelType: 'device',
            },
            options.search,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/vending-machines/orders',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.BaseOrder,
    OrderProps,
    {
        paymentMethod?: string;
        shippingMethod?: string;
        orderStatus?: string;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
});
export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await dispatch(baseActions.load(options));
            await dispatch(loadConfiguredIntegrationsCountByType());
        },
};
