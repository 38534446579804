import {
    Pagination,
    Sorting,
    Ownership,
    SlideshowsPage,
    CreateSlideshowPayload,
    UpdateSlideshowPayload,
    ShareSlideshowPayload,
    Slideshow,
    DuplicateSlideshowPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class SlideshowEndpoint extends Endpoint {
    public async GetSlideshows(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
            includeShared?: string;
            ownedByCompany?: string;
            tags?: string;
            tagsFilterOperator?: string;
        },
        userLocale?: string,
    ): Promise<SlideshowsPage> {
        let url = '/slideshow/overview';
        if (ownership.type !== 'all') {
            url = `/slideshow/${ownership.type}/${ownership.ownerId}/overview`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters: {
                    ...filters,
                    tags: filters?.tags ? filters.tags.split(',') : undefined,
                    locale: userLocale,
                    tagsFilterOperator: filters?.tagsFilterOperator,
                },
                search,
            },
        });

        this.guardResponseSchema('#/definitions/SlideshowsPage', response);

        return response;
    }

    public async GetSlideshowDetails(slideshowId: string): Promise<Slideshow> {
        const response = await this.client.get({
            url: '/slideshow/' + slideshowId + '/details',
        });

        this.guardResponseSchema('#/definitions/Slideshow', response);

        return response;
    }

    public async CreateSlideshow(payload: CreateSlideshowPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateSlideshow',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\CreateSlideshow\\CreateSlideshow',
                payload,
            },
        });
    }

    public async UpdateSlideshow(payload: UpdateSlideshowPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateSlideshow',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\UpdateSlideshow\\UpdateSlideshow',
                payload,
            },
        });
    }

    public async RemoveSlideshow(slideshowId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveSlideshow',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\RemoveSlideshow\\RemoveSlideshow',
                payload: {
                    slideshowId,
                },
            },
        });
    }

    public async ShareSlideshow(payload: ShareSlideshowPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ShareSlideshow',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\ShareSlideshow\\ShareSlideshow',
                payload,
            },
        });
    }

    public async DuplicateSlideshow(payload: DuplicateSlideshowPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DuplicateSlideshow',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\DuplicateSharedSlideshow\\DuplicateSharedSlideshow',
                payload,
            },
        });
    }
}
