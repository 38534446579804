const imagesCache = new Map<string, HTMLImageElement>();

export function loadImageFromCache(cacheKey: string, imageUrl: string, onLoad: (img: HTMLImageElement) => void) {
    let img = imagesCache.get(cacheKey);
    let fromCache = true;
    if (!img) {
        img = document.createElement('img');
        imagesCache.set(cacheKey, img);
        fromCache = false;
    }

    if (fromCache && img.complete) {
        onLoad(img);
        return;
    }

    const handleLoad = () => {
        img.removeEventListener('load', handleLoad);
        onLoad(img);
    };

    img.addEventListener('load', handleLoad);

    if (img.src !== imageUrl) {
        img.src = imageUrl;
    }
}
