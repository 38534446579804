import {
    ProductImportPreviewPayload,
    ProductImportPreview,
    InitializeCsvProductStockAndPriceImportPayload,
    InitializeCsvProductSelectionIncludedProductsImportPayload,
    ImportType,
    ImportsPage,
    ImportDetails,
    ImportErrorsPage,
    Pagination,
    Sorting,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class ImportEndpoint extends Endpoint {
    public async GetCsvImports(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            importType?: ImportType;
        },
    ): Promise<ImportsPage> {
        const url = '/import/overview';

        const csvImports = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/ImportsPage', csvImports);

        return csvImports;
    }

    public async GetCsvImportDetail(importId: string): Promise<ImportDetails> {
        const url = `/import/details/${importId}`;

        const csvImportDetails = await this.client.get({
            url,
        });

        this.guardResponseSchema('#/definitions/ImportDetails', csvImportDetails);

        return csvImportDetails;
    }

    public async GetImportErrors(importId: string, pagination: Pagination, sorting: Sorting): Promise<ImportErrorsPage> {
        const url = `/import/errors/${importId}`;

        const importErrors = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/ImportErrorsPage', importErrors);

        return importErrors;
    }

    public async Preview(payload: ProductImportPreviewPayload): Promise<ProductImportPreview> {
        const response: ProductImportPreview = await this.client.post({
            url: '/csv/import/preview',
            body: payload,
            params: {
                pagination: payload.pagination,
            },
        });

        this.guardResponseSchema('#/definitions/ProductImportPreview', response);

        return response;
    }

    public async InitializeProductStockPriceCsvImport(payload: InitializeCsvProductStockAndPriceImportPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InitializeProductStockPriceCsvImport',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Import\\InitializeProductStockPriceCsvImport\\InitializeProductStockPriceCsvImport',
                payload,
            },
        });
    }

    public async InitializeIncludedProductCsvImport(payload: InitializeCsvProductSelectionIncludedProductsImportPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InitializeIncludedProductCsvImport',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Product\\Import\\ImportSelectionIncludedProducts\\InitializeIncludedProductCsvImport\\InitializeIncludedProductCsvImport',
                payload,
            },
        });
    }
}
