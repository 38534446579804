import {
    Pagination,
    Ownership,
    ProductWallsPage,
    CreateProductWallPayload,
    UpdateProductWallPayload,
    ShareProductWallPayload,
    ProductWall,
    Sorting,
    DuplicateProductWallPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class ProductWallEndpoint extends Endpoint {
    public async GetProductWalls(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
            locale?: string;
            includeShared?: string;
            screenResolution?: string;
            ownedByCompany?: string;
            tags?: string;
            tagsFilterOperator?: string;
        },
        userLocale?: string,
    ): Promise<ProductWallsPage> {
        let url = '/product-wall/overview';
        if (ownership.type !== 'all') {
            url = `/product-wall/${ownership.type}/${ownership.ownerId}/overview`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters: {
                    ...filters,
                    tags: filters?.tags ? filters.tags.split(',') : undefined,
                    locale: filters?.locale ? filters?.locale : filters?.tags ? userLocale : undefined,
                    tagsFilterOperator: filters?.tagsFilterOperator,
                },
                search,
            },
        });

        this.guardResponseSchema('#/definitions/ProductWallsPage', response);

        return response;
    }

    public async GetProductWallDetails(productWallId: string): Promise<ProductWall> {
        const response = await this.client.get({
            url: '/product-wall/' + productWallId + '/details',
        });

        this.guardResponseSchema('#/definitions/ProductWall', response);

        return response;
    }

    public async CreateProductWall(payload: CreateProductWallPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateProductWall',
            body: {
                commandName: 'App\\Domain\\WriteModel\\ProductWall\\CreateProductWall\\CreateProductWall',
                payload,
            },
        });
    }

    public async UpdateProductWall(payload: UpdateProductWallPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateProductWall',
            body: {
                commandName: 'App\\Domain\\WriteModel\\ProductWall\\UpdateProductWall\\UpdateProductWall',
                payload,
            },
        });
    }

    public async RemoveProductWall(productWallId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveProductWall',
            body: {
                commandName: 'App\\Domain\\WriteModel\\ProductWall\\RemoveProductWall\\RemoveProductWall',
                payload: {
                    productWallId,
                },
            },
        });
    }

    public async ShareProductWall(payload: ShareProductWallPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ShareProductWall',
            body: {
                commandName: 'App\\Domain\\WriteModel\\ProductWall\\ShareProductWall\\ShareProductWall',
                payload,
            },
        });
    }

    public async DuplicateProductWall(payload: DuplicateProductWallPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DuplicateProductWall',
            body: {
                commandName: 'App\\Domain\\WriteModel\\ProductWall\\DuplicateSharedProductWall\\DuplicateSharedProductWall',
                payload,
            },
        });
    }
}
