import * as React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import { Infrastructure } from 'api';

import { Card } from '@/card';
import { Dropdown, InputBase, InputBaseProps } from '@/core';
import { Icon } from '@/icon';

import { DatePicker } from './datePicker/DatePicker';
import { TimePicker } from './TimePicker';

import styles from './DateTimePicker.scss';
import inputStyles from '@/input/Input.scss';

export type ControlledDateTimePickerProps = InputBaseProps & {
    onChangeStart?: () => void;
    onChange?: (newValue?: moment.Moment) => void;
    onChangeComplete?: (newValue?: moment.Moment) => void;
    format?: string;
    active?: boolean;
    placeholder?: string;
    forwardRef?: React.Ref<HTMLInputElement>;
    calendarWidth?: string;
    min?: moment.Moment;
    max?: moment.Moment;
    availableDates?: moment.Moment[];
    hideIcon?: boolean;
    notInDropdown?: boolean;
    showRemove?: boolean;
    small?: boolean;
    shouldCloseOnSelect?: boolean;
    disableTextInput?: boolean;
};

export type ControlledDateTimePickerPropsWithValue = ControlledDateTimePickerProps & {
    value: null | moment.Moment;
};

class ControlledDateTimePicker extends InputBase<ControlledDateTimePickerPropsWithValue> {
    private readonly dropdown = React.createRef<Dropdown>();
    private readonly autoFocusedInput = React.createRef<any>();
    private inputElement: HTMLInputElement | undefined;
    private inputValue: string;
    private changedFromInput: boolean;
    private branchTimezone: string = 'UTC';

    constructor(props: ControlledDateTimePickerPropsWithValue) {
        super(props);
        this.setInputValue(props.value ? moment(props.value).format(ControlledDateTimePicker.getFormat(props)) : null);
        this.changedFromInput = false;
        this.setBranchTimezone();
    }

    componentDidUpdate() {
        if (!this.changedFromInput) {
            this.setInputValue(this.props.value ? moment(this.props.value).format(ControlledDateTimePicker.getFormat(this.props)) : null);
        }
        this.changedFromInput = false;
    }

    componentDidMount() {
        this.setInputValue(this.props.value ? moment(this.props.value).format(ControlledDateTimePicker.getFormat(this.props)) : null);
    }
    protected setBranchTimezone() {
        try {
            const branchTimezone = Infrastructure.Container.getConstant('branchTimezone');
            this.branchTimezone = branchTimezone || 'UTC';
        } catch (e) {
            console.log('Could not get branch timezone', e);
        }
    }
    protected hasError(): boolean {
        return (
            !!this.props.error ||
            (!!this.inputValue && !this.valueIsValid(moment(this.inputValue, ControlledDateTimePicker.getFormat(this.props))))
        );
    }

    protected renderInput(): React.ReactNode {
        const { disabled, notInDropdown } = this.props;

        if (notInDropdown) {
            return this.renderDropdownBody();
        }

        return (
            <Dropdown
                ref={this.dropdown}
                headerRenderer={this.renderHeader}
                body={close => this.renderDropdownBody(close)}
                bodyClassName={styles.DateTimePickerDropdownBody}
                disabled={disabled}
                doNotCloseOnHeaderClick={true}
                disableFocus={true}
                onOpen={(fromClick: boolean) => {
                    if (fromClick && this.autoFocusedInput.current) {
                        this.autoFocusedInput.current.focus();
                    }
                }}
                onClose={(fromClick: boolean) => {
                    if (fromClick && this.inputElement) {
                        this.inputElement.focus();
                    }
                }}
            />
        );
    }

    private renderHeader = (isOpen: boolean) => {
        const { name, active, placeholder, disabled, disableTextInput, forwardRef, hideIcon, showRemove, small } = this.props;

        return (
            <>
                <input
                    ref={ref => {
                        if (ref) {
                            this.inputElement = ref;
                        } else {
                            this.inputElement = undefined;
                        }

                        if (forwardRef && typeof forwardRef === 'function') {
                            forwardRef(ref);
                        }
                    }}
                    type="text"
                    name={name}
                    style={this.props.style}
                    className={classNames(
                        inputStyles.Input,
                        active || isOpen ? inputStyles.active : null,
                        small ? inputStyles.small : null,
                    )}
                    placeholder={placeholder}
                    value={this.inputValue}
                    disabled={disabled}
                    onChange={event => {
                        if (disableTextInput) {
                            return;
                        }

                        this.changedFromInput = true;
                        this.handleChangeStart();
                        this.handleChange(event);
                        setTimeout(() => {
                            this.changedFromInput = false;
                        }, 100);
                    }}
                    autoComplete="off"
                    data-test-id={this.getDataTestId()}
                />

                {!hideIcon ? (
                    <span className={inputStyles.InputIconRight}>
                        <Icon type="action_calendar" />
                    </span>
                ) : null}

                {this.props.value && showRemove ? (
                    <span
                        className={styles.DateTimePickerInputIconRemove}
                        onClick={() => {
                            this.handleDateChange('');
                            this.handleDateChangeComplete('');
                        }}
                    >
                        <Icon type="action_remove" />
                    </span>
                ) : null}
            </>
        );
    };

    private renderDropdownBody(close?: () => void) {
        const { value, calendarWidth, min, max, notInDropdown } = this.props;

        const body = (
            <>
                {this.showDatePicker() ? (
                    <DatePicker
                        date={value ? value : undefined}
                        month={value || min || max}
                        min={min}
                        max={max}
                        availableDates={this.props.availableDates}
                        onChange={newDateValue => {
                            let newValue;
                            if (!this.props.value) {
                                newValue = newDateValue;
                            } else {
                                newValue = this.props.value.clone();
                                newValue.year(newDateValue.year());
                                newValue.dayOfYear(newDateValue.dayOfYear());
                            }

                            this.handleChangeStart();
                            this.handleDateChange(newValue);
                            this.handleDateChangeComplete(newValue);
                            if (this.props.shouldCloseOnSelect && close) {
                                close();
                            }
                        }}
                        autoFocusedDay={this.autoFocusedInput}
                    />
                ) : null}

                {this.showTimePicker() ? (
                    <TimePicker
                        date={value ? value : undefined}
                        min={min}
                        max={max}
                        onChangeStart={this.handleChangeStart}
                        onChange={this.handleDateChange}
                        onChangeComplete={this.handleDateChangeComplete}
                        showSeconds={this.showTimePickerSeconds()}
                        autoFocusedSlider={!this.showDatePicker() ? this.autoFocusedInput : undefined}
                    />
                ) : null}
            </>
        );

        if (notInDropdown) {
            return body;
        }

        return (
            <Card
                elevated={true}
                onClick={event => event.preventDefault()}
                style={{
                    width: calendarWidth || '260px',
                }}
            >
                {body}
            </Card>
        );
    }

    protected getClassName(): string {
        return classNames(
            super.getClassName(),
            styles.DateTimePickerWrap,
            !this.props.hideIcon ? inputStyles['with-icon-right'] : undefined,
        );
    }

    private handleChangeStart = () => {
        if (this.props.onChangeStart) {
            this.props.onChangeStart();
        }
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.handleDateChangeComplete(event.target.value);
    };

    private handleDateChangeComplete = (newValue: moment.Moment | string) => {
        const dateValue = this.handleDateChange(newValue);

        if (this.props.onChangeComplete && (dateValue === undefined || dateValue.isValid())) {
            this.props.onChangeComplete(dateValue);
        }
    };

    private handleDateChange = (newValue: moment.Moment | string): moment.Moment | undefined => {
        const { onChange, min, max } = this.props;

        let rawValue;
        let dateValue: moment.Moment | undefined;
        if (moment.isMoment(newValue)) {
            rawValue = newValue.format(ControlledDateTimePicker.getFormat(this.props));
            dateValue = newValue.clone();
        } else {
            rawValue = newValue;
            dateValue = moment(rawValue, ControlledDateTimePicker.getFormat(this.props));
            dateValue = dateValue.tz(this.branchTimezone || 'UTC', true);
        }

        if (onChange) {
            if (min && dateValue.isSame(min, 'day')) {
                if (dateValue.hours() < min.hours()) {
                    dateValue.hours(min.hours());
                    dateValue.minutes(min.minutes());
                } else if (dateValue.hours() === min.hours() && dateValue.minutes() < min.minutes()) {
                    dateValue.minutes(min.minutes());
                } else if (
                    dateValue.hours() === min.hours() &&
                    dateValue.minutes() === min.minutes() &&
                    dateValue.seconds() < min.seconds()
                ) {
                    dateValue.seconds(min.seconds());
                }
            }

            if (max && dateValue.isSame(max, 'day')) {
                if (dateValue.hours() > max.hours()) {
                    dateValue.hours(max.hours());
                    dateValue.minutes(max.minutes());
                } else if (dateValue.hours() === max.hours() && dateValue.minutes() > max.minutes()) {
                    dateValue.minutes(max.minutes());
                } else if (
                    dateValue.hours() === max.hours() &&
                    dateValue.minutes() === max.minutes() &&
                    dateValue.seconds() > max.seconds()
                ) {
                    dateValue.seconds(max.seconds());
                }
            }

            if (moment.isMoment(newValue)) {
                rawValue = dateValue.format(ControlledDateTimePicker.getFormat(this.props));
            }

            if (!rawValue) {
                dateValue = undefined;
                this.setInputValue('');
                onChange(undefined);
            } else if (rawValue && this.valueIsValid(dateValue)) {
                this.setInputValue(rawValue);

                onChange(dateValue);
            } else {
                onChange(undefined);

                this.setInputValue(rawValue);
            }
        } else {
            this.setInputValue(rawValue);
        }

        return dateValue;
    };

    private valueIsValid(value: moment.Moment) {
        const { min, max } = this.props;

        if (!value.isValid()) {
            return false;
        }

        if (min && min.isAfter(value)) {
            return false;
        }

        if (max && max.isBefore(value)) {
            return false;
        }

        return true;
    }

    private static getFormat(props: ControlledDateTimePickerProps) {
        return props.format || moment.defaultFormatUtc;
    }

    private showDatePicker(): boolean {
        const format = ControlledDateTimePicker.getFormat(this.props);
        const dateTokens = new RegExp('[XxMQDdEeWwYGg]');

        return dateTokens.test(format);
    }

    private showTimePicker(): boolean {
        const format = ControlledDateTimePicker.getFormat(this.props);
        const dateTokens = new RegExp('[XxAaHhKkmSsZz]');

        return dateTokens.test(format);
    }

    private showTimePickerSeconds(): boolean {
        const format = ControlledDateTimePicker.getFormat(this.props);
        const dateTokens = new RegExp('[XxSs]');

        return dateTokens.test(format);
    }

    private setInputValue(value: string | null) {
        this.inputValue = value || '';

        if (this.inputElement) {
            this.inputElement.value = value || '';

            if (this.wrapperRef && this.wrapperRef.current) {
                if (this.hasError()) {
                    this.wrapperRef.current.classList.add('InputBaseWithErrorWrapper');
                } else {
                    this.wrapperRef.current.classList.remove('InputBaseWithErrorWrapper');
                }
            }
        }
    }

    public open = (): void => {
        this.dropdown.current!.open();
    };

    public close = (): void => {
        this.dropdown.current!.close();
    };
}

export default React.forwardRef<HTMLInputElement, ControlledDateTimePickerPropsWithValue>((props, ref) => {
    return (
        <ControlledDateTimePicker
            forwardRef={ref}
            {...props}
        />
    );
});
