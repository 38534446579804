import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { importApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { makeQuery } from '@/routing';
import { RootState } from '@/store';

type ImportErrorProps = keyof Domain.ImportError;

const defaultSorting: Domain.Sorting<ImportErrorProps> = {
    field: 'lineNumber',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.ImportError, ImportErrorProps>({
    getState: rootState => rootState.productImport.importErrors,
});

export const selectErrorId: Selector<RootState, string> = state => state.productImport.importErrors.importId;

export const setImportId = createAction('@productImport/importErrors/setImportId', withPayloadType<string>());

export const reducerActions = makeReducerActions<Domain.ImportError, ImportErrorProps>({
    reducerPrefix: '@productImport/importErrors',
});

const baseActions = makeActions<Domain.ImportError, ImportErrorProps>({
    dataTableSaveKey: 'importErrors-v6',
    loadApi: options => {
        const importId = selectErrorId(options.state);
        if (importId) {
            return importApi.GetImportErrors(importId, options.pagination, options.sorting);
        }
    },
    defaultSorting,
    pageSize,
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (importId: string): ThunkAction =>
        async dispatch => {
            await dispatch(setImportId(importId));
            return await dispatch(
                baseActions.load({
                    urlParams: {},
                    urlQuery: makeQuery(''),
                }),
            );
        },
};

export const importErrorsReducer = makeReducer<
    Domain.ImportError,
    ImportErrorProps,
    {
        importId: string;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
    extraCases: builder =>
        builder.addCase(setImportId, (state, action) => {
            state.importId = action.payload;
        }),
});
