import { Domain } from 'api';

import { productWallApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type ProductWallProps = keyof Domain.ProductWall;

export const defaultSorting: Domain.Sorting<ProductWallProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.ProductWall, ProductWallProps>({
    getState: rootState => rootState.productWall.overview,
});

export const reducerActions = makeReducerActions<Domain.ProductWall, ProductWallProps>({
    reducerPrefix: '@productWall/overview',
});

export const actions = makeActions<
    Domain.ProductWall,
    ProductWallProps,
    {
        companyId?: Domain.Company['companyId'];
        branchId?: Domain.Branch['branchId'];
        locale?: Domain.Locale;
        includeShared?: string;
        ownedByCompany?: string;
    }
>({
    dataTableSaveKey: 'productWallsOverview-v5',
    loadApi: options =>
        productWallApi.GetProductWalls(
            options.ownership,
            options.pagination,
            options.sorting,
            options.search,
            options.filters,
            options.locale,
        ),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/presentations/product-walls',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.ProductWall, ProductWallProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
