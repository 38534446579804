import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { importApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import * as importErrorsState from '@/ProductImport/importErrorsState';
import { URLParams, URLQuery } from '@/routing';

export const selectors = makeSelectors<Domain.ImportDetails>({
    getState: rootState => rootState.productImport.details,
});

export const reducerActions = makeReducerActions<Domain.ImportDetails>({
    reducerPrefix: '@productImport/details',
});

const baseActions = makeActions<Domain.ImportDetails>({
    loadApi: options => importApi.GetCsvImportDetail(options.urlParams.importId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([
                dispatch(baseActions.load(options)),
                dispatch(importErrorsState.actions.setPageSize(10)),
                dispatch(importErrorsState.actions.setPaginationPage(1)),
                dispatch(importErrorsState.actions.load(options.urlParams.importId)),
            ]);
        },
};

export const detailsReducer = makeReducer<Domain.ImportDetails>({
    reducerActions,
});
