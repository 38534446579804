import * as React from 'react';
import { useSelector } from 'react-redux';

import { config, configUtils } from 'config';
import { Device, maybeSelectLoggedInUserOwnershipIds } from 'data-store';

import useVideoCallTrigger from './useVideoCallTrigger';
import { useVideoCallContext } from './VideoCallContext';
import * as callManager from './VideoCallManager';

export default function useVideoCall() {
    const device = useSelector(Device.globalSelectionState.selectGlobalSelectedDevice);
    const ownershipIds = useSelector(maybeSelectLoggedInUserOwnershipIds);
    const enableNightHatchV2 = configUtils.getNightHatchV2IsEnabled(ownershipIds.companyId || '');
    const context = useVideoCallContext();
    const trigger = useVideoCallTrigger();
    const [panelPosition, setPanelPosition] = React.useState({
        right: 0,
        top: 0,
    });

    const videoStream = React.useRef<MediaStream | null>(null);
    const [audioElement, setAudioElement] = React.useState<HTMLAudioElement | undefined>();
    const [audioAlertElement, setAlertAudioElement] = React.useState<HTMLAudioElement | undefined>();
    const [showAudioPermissionsMessage, setShowAudioPermissionsMessage] = React.useState(false);

    const openPanel = () => trigger.context.setPanelIsOpened(true);
    const closePanel = () => {
        if (!trigger.context.panelCanBeClosed) {
            return;
        }
        trigger.context.setPanelIsOpened(false);
    };

    const playAlertSound = () => {
        if (!audioAlertElement) {
            return;
        }

        audioAlertElement.currentTime = 0;
        const playPromise = audioAlertElement.play();

        if (playPromise) {
            playPromise.catch(() => {
                console.error('failed to play sound');
                setShowAudioPermissionsMessage(true);
            });
        }
    };

    callManager.onRegistrationChanged(() => {
        if (callManager.isFailingToRegister()) {
            trigger.context.setRegistrationStatus('registration-failing');
            openPanel();
        } else if (callManager.isRegistered()) {
            trigger.context.setRegistrationStatus('registered');
        } else {
            trigger.context.setRegistrationStatus('registering');
        }
    });

    callManager.onRegistrationFailed(() => {
        trigger.context.setRegistrationStatus('registration-failing');
        openPanel();
    });

    callManager.onIncomingCallStatusChange(() => {
        const isMuted = callManager.isMuted();
        trigger.context.setIsMuted(isMuted);
        const activeIncomingCall = callManager.incomingCall();
        if (activeIncomingCall) {
            if (callManager.incomingCallInProgress()) {
                if (audioAlertElement) {
                    audioAlertElement.pause();
                }
                trigger.context.setCallStatus('in-progress');

                const tracks = callManager.incomingCallTracks();
                if (tracks.length === 0) {
                    if (audioElement) {
                        audioElement.srcObject = null;
                    }

                    videoStream.current = null;

                    if (context.videoElement) {
                        context.videoElement.srcObject = null;
                    }

                    if (context.pipVideoElement) {
                        context.pipVideoElement.srcObject = null;
                    }
                } else {
                    for (const track of tracks) {
                        if (track.kind === 'audio') {
                            if (audioElement) {
                                audioElement.srcObject = track.stream;
                            }
                        } else if (track.kind === 'video') {
                            videoStream.current = track.stream;

                            if (context.videoElement) {
                                context.videoElement.srcObject = track.stream;
                            }
                            if (context.pipVideoElement) {
                                context.pipVideoElement.srcObject = track.stream;
                            }
                        }
                    }
                }
            } else {
                if (trigger.context.shouldAutoAnswerNextCall) {
                    trigger.context.autoAnswerNextCall(false);
                    callManager.answerIncomingCall();
                } else {
                    trigger.context.setCallStatus('ringing');
                    playAlertSound();
                }
            }
        } else {
            if (audioAlertElement) {
                audioAlertElement.pause();
            }
            trigger.context.setCallStatus('none');
        }
    });

    React.useEffect(() => {
        if (context.videoElement) {
            context.videoElement.srcObject = videoStream.current;
        }

        if (context.pipVideoElement) {
            context.pipVideoElement.srcObject = videoStream.current;
        }
    }, [context.videoElement, context.pipVideoElement, videoStream.current]);

    React.useEffect(() => {
        const element = trigger.context.triggerElement;
        if (element) {
            const box = element.getBoundingClientRect();
            setPanelPosition({
                right: window.innerWidth - box.right,
                top: box.bottom,
            });
        } else {
            setPanelPosition({
                right: 0,
                top: 0,
            });
        }
    }, [trigger.context.triggerElement]);

    React.useEffect(() => {
        if (trigger.isEnabled && trigger.sipCredentials.extension && trigger.sipCredentials.secret) {
            callManager.register({
                sipExtension: trigger.sipCredentials.extension,
                sipUri: `sip:${trigger.sipCredentials.extension}@${config.videoCalling.sipHost}`,
                sipSecret: trigger.sipCredentials.secret,
            });
        } else {
            callManager.unregister();
        }
    }, [trigger.isEnabled, trigger.sipCredentials]);

    return {
        ...trigger,
        panel: {
            position: panelPosition,
            isOpened: !trigger.context.panelCanBeClosed || trigger.context.panelIsOpened,
            open: openPanel,
            close: closePanel,
            handleClickInsidePanel: trigger.handleClickInsidePanel,
        },
        deviceName: device?.name || '',
        videoElement: context.videoElement,
        setVideoElement: context.setVideoElement,
        audioElement,
        setAudioElement,
        audioAlertElement,
        setAlertAudioElement,
        answerIncomingCall: callManager.answerIncomingCall,
        rejectIncomingCall: callManager.rejectIncomingCall,
        showAudioPermissionsMessage,
        playAlertSound,
        mute: callManager.mute,
        unmute: callManager.unmute,
        enableNightHatchV2,
    };
}
