import { Domain } from 'api';
import { computeProductSize, PRODUCT_SCALE_ALGORITHM_VERSION } from 'utils';

import { DropArea, ShelfStyleParameters, PlacedProduct, Layout, ProductImageSizes } from '../types';
import getSelectedImage from './getSelectedImage';

export default function makeShelfDropAreas(
    placedProducts: PlacedProduct[],
    shelfHeight: number,
    layout: Layout,
    screenResolution: Domain.DeviceScreenResolution,
    productImageSizes: ProductImageSizes,
    draggedProduct?: Domain.SlideshowProduct,
): DropArea[] {
    const LayoutParams = ShelfStyleParameters[layout.style];

    placedProducts = placedProducts.sort((a, b) => a.x - b.x);

    const dropAreas: {
        x: number;
        width: number;
        tooSmall: boolean;
    }[] = [];

    if (placedProducts.length === 0) {
        dropAreas.push(
            makeShelfDropArea(
                screenResolution,
                0,
                layout.width,
                shelfHeight,
                LayoutParams.productAreaPadding,
                productImageSizes,
                draggedProduct,
            ),
        );
    } else {
        for (let i = 0; i < placedProducts.length; i++) {
            let placedProductPrevX = 0;
            if (i > 0) {
                placedProductPrevX = placedProducts[i - 1].x + placedProducts[i - 1].width;
            }

            const potentialWidth = placedProducts[i].x - placedProductPrevX;

            if (potentialWidth > LayoutParams.minProductAreaWidth) {
                dropAreas.push(
                    makeShelfDropArea(
                        screenResolution,
                        placedProductPrevX,
                        potentialWidth,
                        shelfHeight,
                        LayoutParams.productAreaPadding,
                        productImageSizes,
                        draggedProduct,
                    ),
                );
            }
        }

        const prevX = placedProducts[placedProducts.length - 1].x + placedProducts[placedProducts.length - 1].width;
        const potentialLeftWidth = layout.width - prevX;
        if (potentialLeftWidth > LayoutParams.minProductAreaWidth) {
            dropAreas.push(
                makeShelfDropArea(
                    screenResolution,
                    prevX,
                    potentialLeftWidth,
                    shelfHeight,
                    LayoutParams.productAreaPadding,
                    productImageSizes,
                    draggedProduct,
                ),
            );
        }
    }

    return dropAreas;
}

function makeShelfDropArea(
    screenResolution: Domain.DeviceScreenResolution,
    x: number,
    width: number,
    shelfHeight: number,
    padding: number,
    productImageSizes: ProductImageSizes,
    draggedProduct?: Domain.SlideshowProduct,
): DropArea {
    let tooSmall = false;

    if (!draggedProduct) {
        tooSmall = true;
    } else {
        const selectedImage = getSelectedImage(undefined, draggedProduct);
        const imageSize = productImageSizes.get(selectedImage.url);
        if (imageSize) {
            const productSize = computeProductSize(
                screenResolution,
                draggedProduct,
                selectedImage.url,
                imageSize,
                true,
                PRODUCT_SCALE_ALGORITHM_VERSION,
            );

            if (width < productSize.width || shelfHeight < productSize.height) {
                tooSmall = true;
            }
        }
    }

    return {
        x: x + padding,
        width: width - padding * 2,
        tooSmall,
    };
}
