import { CreateOrderDeliveriesPayload } from '../Domain';
import Endpoint from './Endpoint';

export default class DeliveryEndpoint extends Endpoint {
    public async CreateOrderDeliveries(payload: CreateOrderDeliveriesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateOrderDeliveries',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\Delivery\\CreateOrderDeliveries\\CreateOrderDeliveries',
                payload,
            },
        });
    }

    public async StartDelivery(payload: { deliveryId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/StartDelivery',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\Delivery\\StartDelivery\\StartDelivery',
                payload,
            },
        });
    }

    public async MarkDeliverySuccessful(payload: { deliveryId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MarkDeliverySuccessful',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\Delivery\\MarkDeliverySuccessful\\MarkDeliverySuccessful',
                payload,
            },
        });
    }

    public async MarkDeliveryFailed(payload: { deliveryId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MarkDeliveryFailed',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\Delivery\\MarkDeliveryFailed\\MarkDeliveryFailed',
                payload,
            },
        });
    }

    public async MarkDeliveryPickedUpSuccessful(payload: { deliveryId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MarkDeliveryPickedUpSuccessful',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\Delivery\\MarkDeliveryPickedUpSuccessful\\MarkDeliveryPickedUpSuccessful',
                payload,
            },
        });
    }

    public async MarkDeliveryPickedUpFailed(payload: { deliveryId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MarkDeliveryPickedUpFailed',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\Delivery\\MarkDeliveryPickedUpFailed\\MarkDeliveryPickedUpFailed',
                payload,
            },
        });
    }

    public async RequestRobotDelivery(payload: { deliveryId: string; robotOutputDestinationId: number }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RequestRobotDelivery',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Pickup\\Delivery\\RequestRobotDelivery\\RequestRobotDelivery',
                payload,
            },
        });
    }

    public async CheckIfRobotDeliveryCanBeDelivered(deliveryId: string): Promise<boolean> {
        try {
            await this.client.get({
                url: `/pickup/${deliveryId}/delivery-health-check`,
            });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}
