import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom';

import { Route as IRoute, store, I18n } from 'data-store';
import { VideoCallContextProvider, VideoCall } from 'video-call';

import RouteError from '@/Pages/Errors/RouteError';

import AppCore from './AppCore';
import AppRoute from './AppRoute';

let router: ReturnType<typeof createBrowserRouter>;

export default function App({ routes }: { routes: IRoute[] }) {
    return (
        <ReduxProvider
            store={store}
            stabilityCheck="never"
        >
            <I18n.I18nProvider>
                <AppCore>
                    <VideoCallContextProvider>
                        <Routes routes={routes} />
                        <VideoCall />
                    </VideoCallContextProvider>
                </AppCore>
            </I18n.I18nProvider>
        </ReduxProvider>
    );
}

function Routes({ routes }: { routes: IRoute[] }) {
    if (!router) {
        router = createBrowserRouter(
            createRoutesFromElements(
                routes.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={<AppRoute route={route} />}
                        errorElement={<RouteError />}
                    />
                )),
            ),
        );
    }

    return <RouterProvider router={router} />;
}
