import { DeviceTransactionPaymentStatus } from './DeviceTransaction';
import { Country, Currency, Locale } from './Localisation';
import { Pagination } from './Pagination';
import { PickupStatus, PickupProductsCollection } from './Pickup';
import { Price } from './Price';
import { ProductCodes } from './ProductCode';
import { Sorting } from './Sorting';
import { VendingMachineDelivery } from './VendingMachineDelivery';
import { WebshopShippingMethodType } from './WebshopShippingMethod';

export const WebshopOrderPickupFrom = ['LockerOrVendingMachine', 'pharmacy'] as const;
export type WebshopOrderPickupFrom = (typeof WebshopOrderPickupFrom)[number];

export const OrderStatuses = [
    'pending',
    'pendingPayment',
    'canceled',
    'complete',
    'processing',
    'readyToPickUpInStore',
    'pickedUpInStore',
    // Matic only
    'deliveryInProgress',
    // Webshop only
    'closed',
    'suspectedFraud',
    'onHold',
    'paymentReview',
] as const;
export type OrderStatus = (typeof OrderStatuses)[number];

export const ErpOrderSyncStatus = ['success', 'failed'] as const;
export type ErpOrderSyncStatus = (typeof ErpOrderSyncStatus)[number];

export type TrasnactionDetails = {
    transactionId: string;
    paymentUrl?: string | null;
    paymentStatus?: DeviceTransactionPaymentStatus;
    terminalHash?: string;
    createdAt: string;
    description?: string;
};
export interface BaseOrderPetDetails {
    age?: string;
    weight?: string;
    species?: string;
}
export interface BaseOrder {
    orderId: string;
    orderNumber: string;
    externalReferenceId: string;
    salesChannelId: string;
    companyId?: string;
    branchId?: string;
    total: Price;
    taxes: Price;
    discount: Price;
    shipping: Price;

    orderStatus: OrderStatus;

    orderSource?: string | null;
    createdAt: string;
    updatedAt?: string;
    companyName?: string | null;
    branchName?: string | null;

    pickupDetails?: {
        pickupId: string;
        pickupCode: string;
        pickupStatus: PickupStatus;
        products: PickupProductsCollection;
        createdAt: string;
    }[];

    erpOrderSyncStatus: ErpOrderSyncStatus | null;
    isPickedUpSuccessfullyFromVendingMachine?: boolean | null;

    deliveries: VendingMachineDelivery[];
    transactions?: TrasnactionDetails[] | null;
    customerName?: string | null;
    customerFirstName?: string | null;
    customerLastName?: string | null;
    shippingMethod?: WebshopShippingMethodType | null;
    shippingName?: string | null;
    paymentMethod?: string | null;
    shipmentCanBeStarted?: boolean | null;
    prescriptionBarcodes?: string[] | null;
    isUnderReview?: boolean | null;
    flaggedForReviewOn?: string | null;

    preferPickupFrom?: WebshopOrderPickupFrom | null;
}

export interface OrderLine {
    orderLineId?: string;
    productId?: string | null;
    productCodes: ProductCodes;
    name: string;
    quantity: number;
    unitPriceIncludingVat: Price;
    unitPriceExcludingVat: Price;
    totalIncludingVat: Price;
    totalExcludingVat: Price;
    discountExcludingVat: Price;
    discountIncludingVat: Price;
    vatAmount: Price;
    vatPercentage: number;
    discountPercentage: number;
    treatedPets?: BaseOrderPetDetails[];
    details?:
        | {
              eScriptPatientDetails?:
                  | {
                        eScriptToken: string;
                        patientFirstName: string;
                        patientLastName: string;
                        patientEmail?: string | null;
                        patientPhone?: string | null;
                    }
                  | []
                  | null;
          }
        | []
        | null;
}

export type BaseOrderDetails = Omit<BaseOrder, 'branchId' | 'companyId'> & {
    companyId: string;
    branchId: string;
    orderLines: OrderLine[];
    customerLocale?: Locale | null;
    customerEmailAddress?: string | null;
    confirmationEmailAddress?: string | null;
    customerPhone?: string | null;
    customerAddress?: string | null;
    customerZipCode?: string | null;
    customerCity?: string | null;
    customerCountry?: Country | null;
    shippingAddress?: string | null;
    shippingZipCode?: string | null;
    shippingCity?: string | null;
    shippingCountry?: Country | null;
    currency: Currency;
    remarks?: string | null;
};

export type LastOrderDetails = {
    orderId: BaseOrderDetails['orderId'];
    orderLines: BaseOrderDetails['orderLines'];
};

export const CustomTransactionFilterOptions = ['failedAttemptsOnly', 'paidAttemptsOnly', 'failedAndPaidAttemptsOnly'] as const;
export type CustomTransactionFilterOptions = (typeof CustomTransactionFilterOptions)[number];

export interface OrdersPage {
    items: BaseOrder[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof BaseOrder> | null;
    search?: string | null;
}
