import * as React from 'react';

import refreshOnError from '../refreshOnError';

type ErrorHandler = (error: Error) => void;

export default class ErrorBoundary extends React.Component<
    any,
    {
        error?: Error;
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    static errorHandler: ErrorHandler | undefined;

    static getDerivedStateFromError(error: Error) {
        return {
            error,
        };
    }

    static onError(handler: ErrorHandler | undefined) {
        ErrorBoundary.errorHandler = handler;
    }

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.onUnhandledRejection);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
    }

    onUnhandledRejection = (event: PromiseRejectionEvent) => {
        event.promise.catch(error => {
            console.error(error);
            this.setState(ErrorBoundary.getDerivedStateFromError(error));
        });
    };

    render() {
        if (this.state.error) {
            if (ErrorBoundary.errorHandler) {
                ErrorBoundary.errorHandler(this.state.error);
            } else {
                refreshOnError();
                return <span />;
            }
        }

        return this.props.children;
    }
}
