import { Endpoint, Infrastructure } from 'api';
import { config } from 'config';
import { setLogActivityFn } from 'logger';
import { browserStorage } from 'utils';

Infrastructure.Container.setConstant('fingerprint', browserStorage.getDeviceFingerprint());
Infrastructure.Container.setConstant('apiBaseUrl', config.apiBaseUrl);
Infrastructure.Container.setConstant('deviceSignalingIsEnabled', !config.featureFlags.disableDevDeviceSignalingAndMonitoring);
Infrastructure.Container.setConstant('deviceSignalingServerHost', config.deviceSignalingServerHost);
Infrastructure.Container.setConstant('lochtingDeviceBackendBaseUrl', config.lochtingDeviceBackendBaseUrl);
Infrastructure.Container.setConstant('locale', null);
Infrastructure.Container.setConstant('apiValidationThrowsException', config.apiValidationThrowsException);
Infrastructure.Container.setService('apiClient', new Infrastructure.Api.HttpApiClient());

export const authenticationApi = new Endpoint.AuthenticationEndpoint();
export const localisationApi = new Endpoint.LocalisationEndpoint();
export const userApi = new Endpoint.UserEndpoint();
export const companyApi = new Endpoint.CompanyEndpoint();
export const branchApi = new Endpoint.BranchEndpoint();
export const productSourceApi = new Endpoint.ProductSourceEndpoint();
export const deviceApi = new Endpoint.DeviceEndpoint();
export const deviceOrderApi = new Endpoint.DeviceOrderEndpoint();
export const mediaItemApi = new Endpoint.MediaItemEndpoint();
export const availableProductApi = new Endpoint.AvailableProductEndpoint();
export const productSelectionApi = new Endpoint.ProductSelectionEndpoint();
export const customProductApi = new Endpoint.CustomProductEndpoint();
export const productWallApi = new Endpoint.ProductWallEndpoint();
export const customPageApi = new Endpoint.CustomPageEndpoint();
export const slideshowApi = new Endpoint.SlideshowEndpoint();
export const timelineApi = new Endpoint.TimelineEndpoint();
export const translationApi = new Endpoint.TranslationEndpoint();
export const brandApi = new Endpoint.BrandEndpoint();
export const organisationApi = new Endpoint.OrganisationEndpoint();
export const productCategoryApi = new Endpoint.ProductCategoryEndpoint();
export const webshopApi = new Endpoint.WebshopEndpoint();
export const webshopPageApi = new Endpoint.WebshopPageEndpoint();
export const webshopPageLibraryApi = new Endpoint.WebshopPageLibraryEndpoint();
export const scheduleApi = new Endpoint.ScheduleEndpoint();
export const webshopPrescriptionApi = new Endpoint.WebshopPrescriptionEndpoint();
export const webshopOrderApi = new Endpoint.WebshopOrderEndpoint();
export const webshopNewsArticleApi = new Endpoint.WebshopNewsArticleEndpoint();
export const webshopTagApi = new Endpoint.WebshopTagEndpoint();
export const sharingGroupApi = new Endpoint.SharingGroupEndpoint();
export const webshopDeliveryTimeApi = new Endpoint.WebshopDeliveryTimeEndpoint();
export const webshopLayoutApi = new Endpoint.WebshopLayoutEndpoint();
export const webshopNewsletterSubscriptionApi = new Endpoint.WebshopNewsletterSubscriptionEndpoint();
export const webshopPromotionApi = new Endpoint.WebshopPromotionEndpoint();
export const webshopFaqApi = new Endpoint.WebshopFaqEndpoint();
export const webshopFaqLibraryApi = new Endpoint.WebshopFaqLibraryEndpoint();
export const webshopLibraryNewsArticleApi = new Endpoint.WebshopLibraryNewsArticleEndpoint();
export const deviceMonitoringApi = new Endpoint.DeviceMonitoringEndpoint();
export const vendingMachineCatalogApi = new Endpoint.VendingMachineCatalogEndpoint();
export const integrationApi = new Endpoint.IntegrationEndpoint();
export const fraankApi = new Endpoint.FraankEndpoint();
export const notificationApi = new Endpoint.NotificationEndpoint();
export const productFeedbackApi = new Endpoint.ProductFeedbackEndpoint();
export const deviceSignalingApi = new Endpoint.DeviceSignaling();
export const pickupApi = new Endpoint.PickupEndpoint();
export const resellerApi = new Endpoint.ResellerEndpoint();
export const webshopShippingMethodApi = new Endpoint.WebshopShippingMethodEndpoint();
export const zipCodeApi = new Endpoint.ZipCodeEndpoint();
export const webshopCustomerApi = new Endpoint.WebshopCustomerEndpoint();
export const orderShipmentApi = new Endpoint.OrderShipmentEndpoint();
export const webshopOrderShipmentApi = new Endpoint.WebshopOrderShipmentEndpoint();
export const invoiceApi = new Endpoint.InvoiceEndpoint();
export const billableServiceApi = new Endpoint.BillableServiceEndpoint();
export const pharmacyOnDutyApi = new Endpoint.PharmacyOnDutyEndpoint();
export const emergencyAlertApi = new Endpoint.EmergencyAlertEndpoint();
export const importApi = new Endpoint.ImportEndpoint();
export const qualityLabelApi = new Endpoint.QualityLabelEndpoint();
export const deviceTransactionApi = new Endpoint.DeviceTransactionEndpoint();
export const nightHatchLogApi = new Endpoint.NightHatchLogEndpoint();
export const exportApi = new Endpoint.ExportEndpoint();
export const searchSynonymApi = new Endpoint.SearchSynonymEndpoint();
export const activityLogApi = new Endpoint.ActivityLogEndpoint();
export const stockSourceApi = new Endpoint.StockSourceEndpoint();
export const customerEmailAddressApi = new Endpoint.CustomerEmailAddressEndpoint();
export const dashboardApi = new Endpoint.DashboardEndpoint();
export const orderReviewApi = new Endpoint.OrderReviewEndpoint();
export const deviceBackendApi = new Endpoint.DeviceBackend();
export const apiKeyApi = new Endpoint.ApiKeyEndpoint();
export const webhookApi = new Endpoint.WebhookEndpoint();
export const scientificCategoryApi = new Endpoint.ScientificCategoryEndpoint();
export const collectApi = new Endpoint.CollectEndpoint();
export const webshopAnnouncementApi = new Endpoint.WebshopAnnouncementEndpoint();
export const cmsApi = new Endpoint.CmsEndpoint();
export const webshopStockSubscriptionApi = new Endpoint.WebshopStockSubscriptionEndpoint();
export const externalSourceOrderApi = new Endpoint.ExternalSourceOrderEndpoint();
export const appointmentTypeApi = new Endpoint.AppointmentTypeEndpoint();
export const appointmentsApi = new Endpoint.AppointmentsEndpoint();
export const stockReplenishApi = new Endpoint.StockReplenishEndpoint();
export const deliveryApi = new Endpoint.DeliveryEndpoint();
export const auditLogApi = new Endpoint.AuditLogEndpoint();
export const deviceGoodieBagApi = new Endpoint.DeviceGoodieBagEndpoint();
export const nightHatchOnDutyFeeApi = new Endpoint.NightHatchOnDutyFeeEndpoint();
export const tagsApi = new Endpoint.TagsEndpoint();
export const ordersApi = new Endpoint.OrdersEndpoint();

setLogActivityFn(activityLogApi.LogActivity.bind(activityLogApi));
