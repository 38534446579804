import React, { MouseEventHandler, ReactNode } from 'react';

import { Icon, Tx, IconType } from '..';

import './Pill.scss';

export type ChipColor = 'primary' | 'secondary' | 'tertiary' | 'accent' | 'success' | 'error' | 's1' | 's2' | 's3' | 's4' | 's5' | 'plain';
export type ChipType = 'contained' | 'textOnly' | 'outlined';
type ChipProps = {
    size?: 'xxs' | 'xs' | 'sm' | 'md';
    radius?: 'xs' | 'sm' | 'rounded';
    color?: ChipColor;
    onDelete?: MouseEventHandler<HTMLSpanElement>;
    onClick?: MouseEventHandler<HTMLDivElement>;
    startIconType?: IconType;
    className?: string;
    children: ReactNode;
    style?: React.CSSProperties;
    disabled?: boolean;
    variant?: ChipType;
};

export default function Pill({
    size = 'sm',
    radius = 'sm',
    color = 'primary',
    onDelete,
    onClick,
    startIconType,
    children,
    className,
    style,
    disabled,
    variant = 'contained',
    ...props
}: ChipProps) {
    const chipClassNames = [
        'Pill',
        size,
        `radius-${radius}`,
        color,
        variant === 'outlined' ? 'outlined' : '',
        onClick ? 'clickable' : '',
        className ? className : '',
        variant === 'textOnly' ? 'text-only' : '',
    ].join(' ');
    const iconSize: {
        [key: string]: 'xs' | 'xxs';
    } = {
        xs: 'xxs',
        sm: 'xxs',
        md: 'xs',
    };
    const typographySize: {
        [key: string]: '-xs' | '-sm' | '';
    } = {
        xxs: '-xs',
        xs: '-xs',
        sm: '-sm',
        md: '',
    };

    return (
        <Tx
            level={`body${typographySize[size]}`}
            as="span"
            onClick={onClick}
            className={chipClassNames}
            style={style}
            {...props}
        >
            {startIconType && (
                <span className="chip-icon">
                    <Icon
                        type={startIconType}
                        iconSize={iconSize[size]}
                    />
                </span>
            )}
            <Tx
                as="span"
                level={`body${typographySize[size]}`}
            >
                {children}
            </Tx>
            {onDelete && (
                <span
                    className="chip-delete"
                    onClick={e => {
                        if (!disabled) {
                            e.stopPropagation();
                        }
                        onDelete(e);
                    }}
                    data-test-id="remove"
                >
                    <Icon
                        type="action_remove"
                        iconSize={iconSize[size]}
                    />
                </span>
            )}
        </Tx>
    );
}
